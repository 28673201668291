import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useAddPlayerMutation,
  useGetPlayersQuery,
  useUpdatePlayerMutation,
  useUploadImageMutation,
  useGetTeamsQuery,
} from "@services/api/apiSlice";

import { Link } from "@components/ui/link";
import { Button } from "@components/ui/button";
import { Label } from "@components/ui/label";
import { PageLayout } from "@layout/page-layout";
import { Player, defaultPlayer } from "./Player";
import ImagePicker from "@pickers/imagePicker";
import { usePermissions } from "@/features/permissions/usePermissions";
import { useAccount } from "@/features/accounts/useAccount";
import { useApiToasts } from "@/utils/toasts";

import TeamPickerIcon from "@/assets/icons/team.png";
import SingleEntitySelector from "@/components/pickers/SingleEntitySelector";

import {
  NameInput,
  IdInput,
  IsAvailableInput,
  CreditsInput,
  PointsInput,
  CategoryInput,
  TagsInput,
  DescriptionInput,
} from "@fields/Fields";

import { isEmpty } from "@/utils/utils";

const DEFAULT_IMAGE = import.meta.env.VITE_APP_DEFAULT_IMAGE;

const PlayerPage: React.FC = () => {
  const { canWrite } = usePermissions();
  const { account } = useAccount();
  const { showError, showSuccess } = useApiToasts();
  const { player: playerId } = useParams<{ player: string }>();
  const navigate = useNavigate();

  const { data: players = [], isLoading } = useGetPlayersQuery({
    account: account?.account || "",
  });
  const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery({
    account: account?.account || "",
  });

  const [player, setPlayer] = useState<Player>(defaultPlayer);
  const [errors, setErrors] = useState<Partial<Record<keyof Player, string>>>(
    {}
  );
  const [selectedTeam, setSelectedTeam] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [addNewPlayer] = useAddPlayerMutation();
  const [updatePlayer] = useUpdatePlayerMutation();
  const [uploadImage] = useUploadImageMutation();

  const [isReadOnly, setIsReadOnly] = useState(false);
  const isNew = playerId === "new";

  useEffect(() => {
    if (isLoading) return;
    if (!isNew) {
      const playerToEdit = players.find((player) => player.player === playerId);
      if (!playerToEdit) {
        navigate("/players");
        return;
      }
      setPlayer({ ...playerToEdit, account: account?.account || "" });
      setSelectedTeam(playerToEdit.team);
    }
  }, [players, navigate, isNew, playerId, isLoading, account]);

  useEffect(() => {
    setIsReadOnly(!canWrite());
  }, [canWrite]);

  const updateField = <K extends keyof Player>(field: K, value: Player[K]) => {
    setPlayer((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const saveImage = async (
    file: File | null,
    currentImgUrl: string
  ): Promise<string> => {
    if (!file || !account?.account) return currentImgUrl;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const result = await uploadImage({
        formData,
        account: account.account,
      }).unwrap();
      return result.path;
    } catch (error) {
      showError(error);
      return currentImgUrl;
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    let entityWithImage;
    try {
      if (!isNew) {
        const imagePath = await saveImage(file, player.imgUrl);
        entityWithImage = { ...player, imgUrl: imagePath };
      } else {
        entityWithImage = { ...player, imgUrl: DEFAULT_IMAGE };
      }

      if (isNew) {
        await addNewPlayer(entityWithImage);
        showSuccess(`Added player ${entityWithImage.player}`);
      } else {
        await updatePlayer(entityWithImage);
        showSuccess(`Updated player ${entityWithImage.player}`);
      }
      navigate(`/players`);
    } catch (err) {
      showError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTeamChange = (id: string | undefined) => {
    setSelectedTeam(id || "");
    if (id) {
      updateField("team", id);
    } else {
      updateField("team", "");
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof Player, string>> = {};

    if (!player.player.trim()) newErrors.player = "Player ID is required";
    if (!player.name.trim()) newErrors.name = "Name is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImagePicked = (file: File) => {
    if (file) {
      setFile(file);
    }
  };

  if (isLoading || isLoadingTeams) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout>
      <div>
        <Link to="/players/list" variant="withIcon">
          Back
        </Link>
        <form onSubmit={handleSave} className="mx-4">
          <div className="p-5 bg-neutral-100 rounded-3xl">
            <Label className="text-2xl font-bold my-6">
              {player.name ? player.name : `Create new Player`}
            </Label>

            <div className="grid grid-cols-2 mt-4 gap-4">
              <div className="col-span-1">
                <IsAvailableInput
                  value={player.isAvailable}
                  onChange={(value) => updateField("isAvailable", value)}
                  disabled={isReadOnly}
                />
              </div>
              <div className="col-span-1" />

              <div className="col-span-1">
                <NameInput
                  entity_name="Player"
                  value={player.name}
                  required
                  onChange={(value) => updateField("name", value)}
                  placeholder={`Your Player needs a name`}
                  error={errors.name}
                  disabled={isReadOnly}
                />
              </div>

              <div className="col-span-1">
                <IdInput
                  entity_name="Player"
                  value={player.player}
                  required
                  onChange={(value) => updateField("player", value)}
                  placeholder="And a unique Player ID"
                  disabled={!isNew || isReadOnly}
                  error={errors.account}
                />
              </div>

              <div className="col-span-1">
                <DescriptionInput value="" onChange={() => {}} disabled />
              </div>
              <div className="col-span-1">
                <ImagePicker
                  path={player.imgUrl}
                  id={player.account}
                  onImagePicked={handleImagePicked}
                  size="small"
                  disabled={isReadOnly}
                />
              </div>

              <PointsInput
                value={player.points}
                onChange={(points) => updateField("points", points)}
                helpText="Points assigned to the player"
              />
              <CreditsInput
                value={player.credits}
                onChange={(credits) => updateField("credits", credits)}
                helpText="Credits assigned to the player"
              />

              <CategoryInput
                value={player.category}
                onChange={(value) => updateField("category", value)}
              />
              <TagsInput
                value={player.tags}
                onChange={(value) => updateField("tags", value)}
              />

              <SingleEntitySelector
                entities={teams}
                selectedEntity={selectedTeam}
                isLoading={isLoadingTeams}
                className="rounded-2xl border border-primary-200 bg-white"
                entityType="Team"
                icon={TeamPickerIcon}
                onChange={handleTeamChange}
              />
            </div>
          </div>
          <div className="flex justify-end mt-4 mb-16">
            <Button
              className="uppercase py-6 px-16"
              type="submit"
              disabled={
                isReadOnly ||
                isSubmitting ||
                isEmpty(player.player) ||
                isEmpty(player.name)
              }
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default PlayerPage;
