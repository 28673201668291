import React, { useState } from "react";
import { Label } from "@/components/ui/label";
import { CustomNumberInput } from "@fields/Fields";

const imgEdit = "/images/edit.png";
const imgDone = "/images/done.png";
const imgDelete = "/images/delete.png";

export interface ItemEditProps {
  title: string;
  countTitle: string;
  id: string;
  name: string;
  imgUrl: string;
  count: number;
  onChange: (id: string, count: number) => void;
  onDelete: (id: string) => void;
}

export const ItemEdit: React.FC<ItemEditProps> = ({
  title,
  countTitle,
  id,
  name,
  imgUrl,
  count,
  onChange,
  onDelete,
}) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(count);

  const handleChange = (value: number) => {
    setValue(value);
    onChange(id, value);
    //setProperties({ ...properties, value, error: "" });
  };

  return (
    <div className="flex items-center p-4 border rounded-2xl shadow-sm bg-white border-primary-200 focus:border-indigo-500 hover:border-indigo-500 relative">
      <img
        src={imgUrl}
        alt={name}
        className="w-24 h-24 object-cover rounded-2xl mr-4"
      />

      <div className="flex-grow flex flex-col justify-center">
        <Label htmlFor={`title-${id}`} className="text-lg font-bold mb-2">
          {title}
        </Label>
        <Label htmlFor={`name-${id}`} className="text-gray-600 mb-2">
          {name}
        </Label>
        <div>
          <Label htmlFor={`count-${id}`} className="text-sm text-gray-500 mb-1">
            {countTitle}
          </Label>
          <div className="flex items-center">
            <CustomNumberInput
              id={`count-${id}`}
              value={value}
              onChange={handleChange}
              min={0}
              disabled={!active}
            />
          </div>
        </div>
      </div>

      <div className="absolute top-3 right-3 flex space-x-2">
        <img
          src={active ? imgDone : imgEdit}
          onClick={() => setActive(!active)}
          className="w-4 h-4 cursor-pointer"
          alt={active ? "Edit" : "Done"}
        ></img>
        <img
          src={imgDelete}
          onClick={() => onDelete(id)}
          className="w-4 h-4 cursor-pointer"
          alt="Delete"
        ></img>
      </div>
    </div>
  );
};

ItemEdit.displayName = "ItemEdit";
