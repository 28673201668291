import React from "react";
import { Label } from "@/components/ui/label";
import { RefreshPeriod } from "@features/common/Common";
import RadioButtonRow from "@/components/radiobuttons/RadioButtonRow";

interface RefreshPeriodSelectorProps {
  value: RefreshPeriod;
  onChange: (value: RefreshPeriod) => void;
}

const RefreshPeriodSelector: React.FC<RefreshPeriodSelectorProps> = ({
  value,
  onChange,
}) => {
  const options: { label: string; value: RefreshPeriod }[] = [
    { label: "None", value: "all-time" },
    { label: "Day", value: "daily" },
    { label: "Week", value: "weekly" },
    { label: "Month", value: "monthly" },
  ];

  return (
    <div className="flex flex-col justify-end w-full">
      <Label htmlFor="refresh-period" className="text-sm mb-1.5">
        Refresh Period
      </Label>
      <div className="h-9 flex items-center">
        <RadioButtonRow<RefreshPeriod>
          name="refresh-period"
          value={value}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
};

export default RefreshPeriodSelector;
