import React from "react";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/utils/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import DatePickerIcon from "@/assets/icons/DatePicker.png";

interface DatePickerProps {
  label?: string;
  onChange?: (date: Date | undefined) => void;
  value?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label = "Select Date",
  onChange = () => {},
  value,
}) => {
  const date = value ? new Date(value) : undefined;

  const handleDateSelect = (newDate: Date | undefined) => {
    if (newDate) {
      // Preserve the existing time if there is one
      if (date) {
        newDate.setHours(date.getHours());
        newDate.setMinutes(date.getMinutes());
      }
      onChange(newDate);
    } else {
      onChange(undefined);
    }
  };

  const handleHourChange = (hour: string) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setHours(parseInt(hour));
      onChange(newDate);
    }
  };

  const handleMinuteChange = (minute: string) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setMinutes(parseInt(minute));
      onChange(newDate);
    }
  };

  const hours = Array.from({ length: 24 }, (_, i) => ({
    value: i.toString(),
    label: i.toString().padStart(2, "0"),
  }));

  const minutes = Array.from({ length: 60 }, (_, i) => ({
    value: i.toString(),
    label: i.toString().padStart(2, "0"),
  }));

  return (
    <div className="flex flex-col gap-2 w-full">
      <label className="text-sm font-medium">{label}</label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !date && "text-gray-500"
            )}
          >
            <img src={DatePickerIcon} className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP HH:mm") : "Pick a date and time"}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={handleDateSelect}
            initialFocus
          />
          {date && (
            <div className="flex items-center justify-center gap-2 px-4 py-3 border-t">
              <div className="flex gap-2">
                <Select
                  value={date.getHours().toString()}
                  onValueChange={handleHourChange}
                >
                  <SelectTrigger className="w-[70px]">
                    <SelectValue placeholder="HH" />
                  </SelectTrigger>
                  <SelectContent position="popper">
                    {hours.map((hour) => (
                      <SelectItem key={hour.value} value={hour.value}>
                        {hour.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <span className="text-sm">:</span>
                <Select
                  value={date.getMinutes().toString()}
                  onValueChange={handleMinuteChange}
                >
                  <SelectTrigger className="w-[70px]">
                    <SelectValue placeholder="MM" />
                  </SelectTrigger>
                  <SelectContent position="popper">
                    {minutes.map((minute) => (
                      <SelectItem key={minute.value} value={minute.value}>
                        {minute.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default DatePicker;
