import { formatRFC3339 } from "date-fns";

export interface TimeRange {
  from: string;
  to: string;
}

export const defaultTimeRange: TimeRange = {
  from: formatRFC3339(new Date()),
  to: formatRFC3339(new Date()),
};

export type RefreshPeriod = "daily" | "weekly" | "monthly" | "all-time";

export type LeaderboardElement = "team" | "player" | "teammembers";
export type LeaderboardRankBy = "total" | "average";
export type LeaderboardUnits = "points" | "credits";

export interface Timing {
  maxSecondsToComplete: number;
  minSecondsToRestart: number;
}

export type QuizType = "basic" | "immediate";
export type QuizQuestionType = "single" | "multi";

export interface QuizTiming {
  maxSecondsToComplete: number;
}

export interface QuizChoice {
  id: string; // id for the quiz choice is required. Set by backend if not provided
  imgUrl: string;
  text: string; // text for the quiz choice is required
  correctAnswer: boolean; // Needed to specify if quiz choice is correct
}

export interface QuizQuestionSchema {
  id: string; // Quiz id - if not set, will be set by backend. Need to be unique for the quiz
  type: QuizQuestionType;
  text: string; // Text for the quiz question - required
  imgUrl: string;
  weight: number; // Weight of the question
  strictMode: boolean;
  choices: QuizChoice[];
}

export interface AchievementSteps {
  id: string;
  steps: number;
}

export interface SubObjectives {
  id: string;
  count: number;
  currentCount: number;
}

export interface RequirementLevel {
  id: string;
  ordinal: number;
}

export interface Requirements {
  level: RequirementLevel;
  category: string;
  tags: string[];
  missions: string[];
  achievements: string[]; // Achievement Ids
}

export const defaultRequirements: Requirements = {
  level: { id: "", ordinal: 0 },
  category: "",
  tags: [],
  missions: [],
  achievements: [],
};

export interface Language {
  code: string;
  name: string;
  description: string;
}

// Common properties shared by all gamificiation elements
export interface ElementBase {
  id: string;
  account: string;
  name: string;
  description: string;
  isAvailable: boolean;
  imgUrl: string;
  tags: string[];
  category: string;
  active?: TimeRange | null;
  createdOn?: string | null;
  credits?: number;
  points?: number;
}

export interface ElementWithPlayer extends Omit<ElementBase, "id"> {
  player: string;
  level: {
    id: string;
    name: string;
    description: string;
    imgUrl: string;
  };
}

export type Entity = ElementBase | ElementWithPlayer;

export const defaultElementBase: ElementBase = {
  id: "",
  account: "",
  name: "",
  description: "",
  isAvailable: true,
  imgUrl: "",
  tags: [],
  category: "",
};

export interface Rewards {
  points: number;
  credits: number;
  achievements: AchievementSteps[];
}

export const defaultRewards: Rewards = {
  points: 0,
  credits: 0,
  achievements: [],
};

export interface Stock {
  redeemed: number;
  available: number;
  count: number;
}

export const defaultStock: Stock = {
  redeemed: 0,
  available: 0,
  count: 0,
};
