import React from 'react';
import { Link } from 'react-router-dom';
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils/utils";

// Base styles that will always be applied
const baseStyles =
  "rounded-full px-6 py-2 shadow hover:bg-primary-200 hover:text-primary-500 border-primary-500 border-solid border-2";

const actionButtonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-2xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: `${baseStyles} bg-primary-500 text-primary-foreground hover:bg-primary-200 hover:text-primary-500 border-primary-500`,
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm border hover:bg-secondary/80 hover:border-primary-500",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        custom: baseStyles, // Apply base styles to custom variant
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-2xl px-3 text-xs",
        lg: "h-10 rounded-2xl px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof actionButtonVariants> {
  className?: string;
  onClick?: () => void;
  label?: string;
  to?: string;
  children: React.ReactNode;
}

const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps> (
 (
   { 
    label,
    className,
    variant,
    onClick,
    to,
    children,
  },
  ref
) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (to) {
    return (
      <Link
        to={to}
        onClick={handleClick}
        title={label}
        className={"rounded-full px-6 py-2 bg-primary-500 text-primary-foreground shadow hover:bg-primary-200 hover:text-primary-500 border-primary-500 border-solid border-2 " + (className || '')}>
        {children}
      </Link>
    );
  }

  return (
    <button
      onClick={handleClick}
      title={label}
      ref={ref}
      className={cn(actionButtonVariants({ variant, className }))}>
      { label }
      {children}
    </button>
  );
});

ActionButton.displayName = "ActionButton";

export {ActionButton, actionButtonVariants};
