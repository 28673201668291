import React from "react";
import { Label } from "@/components/ui/label";
import RadioButtonRow from "@/components/radiobuttons/RadioButtonRow";
import { LeaderboardElement } from "@features/common/Common";

interface LeaderboardElementSelectorProps {
  value: LeaderboardElement;
  onChange: (value: LeaderboardElement) => void;
}

const LeaderboardElementSelector: React.FC<LeaderboardElementSelectorProps> = ({
  value,
  onChange,
}) => {
  const options: { label: string; value: LeaderboardElement }[] = [
    { label: "Players", value: "player" },
    { label: "Teams", value: "team" },
    { label: "Team Members", value: "teammembers" },
  ];

  return (
    <div className="flex flex-col justify-end w-full">
      <Label htmlFor="refresh-period" className="text-sm mb-1.5">
        Elements in Use
      </Label>
      <div className="h-9 flex items-center">
        <RadioButtonRow<LeaderboardElement>
          name="refresh-period"
          value={value}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
};

export default LeaderboardElementSelector;
