// LevelPage.tsx
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetLevelsQuery,
  useAddLevelMutation,
  useUpdateLevelMutation,
} from "@services/api/apiSlice";
import { useAccount } from "@features/accounts/useAccount";
import { BaseEntityForm } from "@components/forms/BaseEntityForm";
import RewardsPicker from "@pickers/rewardsPicker";
import LevelObjectivesPicker from "./levelObjectivesPicker";
import { Level, defaultLevel } from "./Level";
import { CategoryInput, TagsInput, OrdinalInput } from "@fields/Fields";
import { Divider } from "@fields/Display";

const LevelPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: levels = [], isLoading } = useGetLevelsQuery({
    account: account?.account || "",
  });
  const [addNewLevel] = useAddLevelMutation();
  const [updateLevel] = useUpdateLevelMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const levelToEdit = levels.find((level) => level.id === id);
      if (
        !account ||
        (levelToEdit && levelToEdit.account !== account.account)
      ) {
        navigate("/levels");
      }
    }
  }, [levels, id, navigate, account, isNew]);

  const levelToEdit = levels.find((level) => level.id === id);
  const currentLevel = isNew
    ? { ...defaultLevel, account: account?.account || "" }
    : levelToEdit || defaultLevel;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Level>
      entityName="Level"
      entityPath="levels/list"
      entity={currentLevel}
      isNew={isNew}
      defaultEntity={defaultLevel}
      addEntity={(entity) => addNewLevel(entity).unwrap()}
      updateEntity={(entity) => updateLevel(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-3 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
            <OrdinalInput
              value={entity.ordinal}
              required
              onChange={(ordinal) => updateField("ordinal", ordinal)}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <LevelObjectivesPicker
              objectives={entity.objectives}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(objectives) => updateField("objectives", objectives)}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <RewardsPicker
              reward={entity.reward}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(reward) => updateField("reward", reward)}
            />
          </div>
        </>
      )}
    />
  );
};

export default LevelPage;
