import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

interface RadioOption<T> {
  value: T;
  label: string;
}

interface RadioButtonRowProps<T extends string> {
  options: RadioOption<T>[];
  value: T;
  onChange: (value: T) => void;
  name: string;
  disabled?: boolean;
  required?: boolean;
}

const RadioButtonRow = <T extends string>({
  options,
  value,
  onChange,
  name,
  disabled = false,
  required = false,
}: RadioButtonRowProps<T>) => {
  return (
    <RadioGroup
      name={name}
      value={value}
      onValueChange={onChange as (value: string) => void}
      className="flex items-center space-x-6"
      disabled={disabled}
      required={required}
    >
      {options.map((option) => (
        <div key={option.value} className="flex items-center space-x-2">
          <RadioGroupItem
            value={option.value}
            id={`${name}-${option.value}`}
            className="h-5 w-5 border border-primary-500 text-primary-500 relative
                  before:content-[''] before:absolute before:inset-[2px] before:rounded-full before:hidden
                  after:content-[''] after:absolute after:inset-[3px] after:rounded-full after:hidden
                  data-[state=checked]:after:block data-[state=checked]:after:bg-primary-500
                  data-[state=checked]:before:block data-[state=checked]:before:border-2 data-[state=checked]:before:border-white"
          />
          <Label
            htmlFor={`${name}-${option.value}`}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {option.label}
          </Label>
        </div>
      ))}
    </RadioGroup>
  );
};

export default RadioButtonRow;
