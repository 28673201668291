import React from "react";
import { NavBar } from "@navigation/navbar";
import { Header } from "@components/layout/header";
import Logo from "@elements/logo";
import FooterLogo from "@assets/footer_logo.png";

interface Props {
  children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />

      <main className="flex container max-w-full xl:max-w-[1200px] xl:m-auto flex-col md:flex-row"> {/* Main content takes remaining space */}
        <aside className="bg-white md:bg-transparent sticky top-0 md:relative flex-1 md:max-w-52 w-full md:w-64 md:p-4 z-40"> {/* Sidebar */}
          <NavBar />
        </aside>

        <div className="flex-1 min-w-64 w-full md:p-4"> {/* Main content area */}
          {children}
        </div>
      </main>

      <footer className="bg-primary-500 mt-8 p-4 text-white text-sm text-center">
        <div className="flex gap-2 items-center xl:max-w-[1200px] xl:m-auto">
          <Logo className="max-w-24 md:max-w-48" src={FooterLogo} alt="GameLayer" />
        </div>
      </footer>
    </div>
  );
};
