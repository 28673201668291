import { toast, useToast } from "@/hooks/use-toast";

export const showErrorToast = (error: unknown) => {
  let description = "An unexpected error occurred";

  if (error instanceof Error) {
    description = error.message;
  } else if (typeof error === "string") {
    description = error;
  }

  toast({
    variant: "destructive",
    title: "Error",
    description,
    duration: 5000, // stays visible for 5 seconds
  });
};

interface ApiErrorResponse {
  error: string;
  errorCode: number;
}

export const useApiToasts = () => {
  const { toast } = useToast();

  const showError = (error: unknown) => {
    let errorMessage = "An unexpected error occurred";

    if (typeof error === "object" && error !== null) {
      if ("data" in error) {
        const errorData = error.data as ApiErrorResponse;
        if (errorData?.error) {
          errorMessage = errorData.error;
        }
      }
    }

    toast({
      variant: "destructive",
      title: `Error ${(error as any)?.data?.errorCode || ""}`,
      description: errorMessage,
    });
  };

  const showSuccess = (message: string) => {
    toast({
      title: "Success",
      description: message,
      className: "bg-green-500 text-white",
    });
  };

  return { showError, showSuccess };
};
