import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetSurveysQuery,
  useAddSurveyMutation,
  useUpdateSurveyMutation,
} from "@services/api/apiSlice";
import { useAccount } from "@features/accounts/useAccount";
import { BaseEntityForm } from "@components/forms/BaseEntityForm";
import RewardsPicker from "@pickers/rewardsPicker";
import { CategoryInput, TagsInput, IsShuffleInput } from "@fields/Fields";
import { Divider } from "@fields/Display";
import { Survey, defaultSurvey } from "./Survey";

const SurveyPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: surveys = [], isLoading } = useGetSurveysQuery({
    account: account?.account || "",
  });
  const [addNewSurvey] = useAddSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const surveyToEdit = surveys.find((level) => level.id === id);
      if (
        !account ||
        (surveyToEdit && surveyToEdit.account !== account.account)
      ) {
        navigate("/surveys");
      }
    }
  }, [surveys, id, navigate, account, isNew]);

  const surveyToEdit = surveys.find((level) => level.id === id);
  const currentSurvey = isNew
    ? { ...defaultSurvey, account: account?.account || "" }
    : surveyToEdit || defaultSurvey;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Survey>
      entityName="Survey"
      entityPath="surveys/list"
      entity={currentSurvey}
      isNew={isNew}
      defaultEntity={defaultSurvey}
      addEntity={(entity) => addNewSurvey(entity).unwrap()}
      updateEntity={(entity) => updateSurvey(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
          </div>
          <Divider />
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <IsShuffleInput
              value={entity.isShuffleRequired}
              onChange={(shuffle) => updateField("isShuffleRequired", shuffle)}
            />
          </div>
          <div className="col-span-2">
            <RewardsPicker
              reward={entity.reward}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(reward) => updateField("reward", reward)}
            />
          </div>
        </>
      )}
    />
  );
};

export default SurveyPage;
