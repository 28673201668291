import React, { useState, useEffect, useCallback } from "react";
import { useGetAchievementsQuery } from "@services/api/apiSlice";
import { PointsInput, CreditsInput } from "@/components/fields/Fields";
import { ListPicker, ListItem } from "@pickers/listPicker";
import ItemPicker from "@pickers/itemPicker";
import { useAccount } from "@/features/accounts/useAccount";
import { Achievement } from "@/features/achievements/Achievement";
import AchievementPicker from "@/assets/icons/AchievementPicker.png";
import { LevelObjectives } from "@/features/levels/Level";
import { SectionHeader } from "@/components/fields/Display";

interface LevelObjectivesPickerProps {
  objectives: LevelObjectives;
  className?: string;
  onChange: (value: LevelObjectives) => void;
}

const LevelObjectivesPicker: React.FC<LevelObjectivesPickerProps> = ({
  objectives,
  className,
  onChange,
}) => {
  const { account } = useAccount();
  const { data: achievements = [], isLoading } = useGetAchievementsQuery({
    account: account?.account || "",
  });

  const [selectedAchievements, setSelectedAchievements] = useState<
    Achievement[]
  >([]);

  // Initialize selected achievements from objectives
  useEffect(() => {
    if (!isLoading && achievements.length > 0 && objectives.achievements) {
      const selected = objectives.achievements
        .filter((item) => achievements.some((ach) => ach.id === item))
        .map((achievementId) => {
          const achievement = achievements.find(
            (ach) => ach.id === achievementId
          )!;
          return { ...achievement };
        });

      setSelectedAchievements(selected);
    }
  }, [isLoading, achievements, objectives.achievements]);

  const handleSelect = useCallback(
    (item: ListItem) => {
      if (isLoading) return;

      const achievement = achievements.find((a) => a.id === item.id);
      if (!achievement) return;

      const newSelection = selectedAchievements.some((a) => a.id === item.id)
        ? selectedAchievements.filter((a) => a.id !== item.id)
        : [
            ...selectedAchievements,
            { ...achievement, steps: achievement.steps || 0 },
          ];

      setSelectedAchievements(newSelection);
      onChange({
        ...objectives,
        achievements: newSelection.map((item) => item.id),
      });
    },
    [isLoading, achievements, selectedAchievements, objectives, onChange]
  );

  const handleDelete = useCallback(
    (id: string) => {
      const newSelection = selectedAchievements.filter(
        (item) => item.id !== id
      );
      setSelectedAchievements(newSelection);
      onChange({
        ...objectives,
        achievements: newSelection.map((item) => item.id),
      });
    },
    [selectedAchievements, objectives, onChange]
  );

  const handlePointsChange = useCallback(
    (points: number) => {
      onChange({
        ...objectives,
        points,
      });
    },
    [objectives, onChange]
  );

  const handleCreditsChange = useCallback(
    (credits: number) => {
      onChange({
        ...objectives,
        credits,
      });
    },
    [objectives, onChange]
  );

  const achievementListItems = achievements.map((item) => ({
    id: item.id,
    name: item.name,
    disabled: selectedAchievements.some((selected) => selected.id === item.id),
  }));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <SectionHeader title="Objectives" />
      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-1">
          <PointsInput
            value={objectives.points}
            onChange={handlePointsChange}
          />
        </div>
        <div className="col-start-2 row-start-1">
          <CreditsInput
            value={objectives.credits}
            onChange={handleCreditsChange}
          />
        </div>
        <div className="col-start-1 row-start-2">
          <ListPicker
            icon={AchievementPicker}
            items={achievementListItems}
            currentValue={selectedAchievements.at(-1)?.id}
            className={className}
            listType="Achievement"
            onSelect={handleSelect}
            mode="select"
          />
        </div>
        <div className="col-start-2 row-start-2 space-y-4">
          {selectedAchievements.map((item) => (
            <ItemPicker
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              title="Achievement Name:"
              name={item.name}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LevelObjectivesPicker;
