import React from "react";
import { Label } from "@/components/ui/label";
import { AccountTypes } from "./Account";
import RadioButtonRow from "@/components/radiobuttons/RadioButtonRow";

interface AccountTypeSelectorProps {
  value: AccountTypes;
  disabled?: boolean;
  onChange: (value: AccountTypes) => void;
}

const AccountTypeSelector: React.FC<AccountTypeSelectorProps> = ({
  value,
  disabled = false,
  onChange,
}) => {
  const options: { label: string; value: AccountTypes }[] = [
    { label: "Paid", value: "paid" },
    { label: "Free", value: "free" },
    { label: "Test", value: "test" },
  ];

  return (
    <div className="flex flex-col justify-end w-full">
      <Label htmlFor="account-type" className="text-sm mb-1.5">
        Account Type
      </Label>
      <div className="h-9 flex items-center">
        <RadioButtonRow<AccountTypes>
          name="refresh-period"
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default AccountTypeSelector;
