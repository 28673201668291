import React from 'react';
import blueLogo from '@assets/blue_logo.png';
import bwLogo from '@assets/gl_bw_logo.png';
import invertedLogo from '@assets/inverted_logo.svg';

interface LogoProps {
  type?: 'blue' | 'bw' | 'inverted';
  src: string | null;
  alt: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ type = 'blue', src, alt, className='max-w-6 max-h-6' }) => {
  const logoTypes = {
    'blue': blueLogo,
    'bw': bwLogo,
    'inverted': invertedLogo
  };
  const source = src ? src : (type ? logoTypes[type] : blueLogo);
  return <img className={className} src={source} alt={alt} />;
};

export default Logo;