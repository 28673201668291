import {
  LeaderboardElement,
  LeaderboardRankBy,
  LeaderboardUnits,
  RefreshPeriod,
  ElementBase,
  TimeRange,
  Requirements,
  Language,
  defaultElementBase,
  defaultTimeRange,
  defaultRequirements,
} from "@features/common/Common";

export interface LeaderboardDrivenBy {
  missions: string[];
}
export interface Leaderboard extends ElementBase {
  period: RefreshPeriod;
  element: LeaderboardElement;
  rankBy: LeaderboardRankBy;
  units: LeaderboardUnits;
  active: TimeRange;
  requirement: Requirements;
  drivenBy: LeaderboardDrivenBy;
  languages: Language[];
}

// Robert: when creating a new empty event, use the defaultEvent
// it will automatically populate fields with proper default values
export const defaultLeaderboard: Leaderboard = {
  ...defaultElementBase,
  period: "all-time",
  element: "player",
  rankBy: "total",
  units: "points",
  active: defaultTimeRange,
  requirement: defaultRequirements,
  drivenBy: { missions: [] },
  languages: [],
};
