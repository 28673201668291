import { formatRFC3339 } from "date-fns";

import {
  TimeRange,
  RefreshPeriod,
  defaultTimeRange,
  defaultElementBase,
  Requirements,
  defaultRequirements,
  Language,
  ElementBase,
  SubObjectives,
  Stock,
  defaultStock,
} from "@features/common/Common";

export interface Raffle extends ElementBase {
  credits: number;
  active: TimeRange;
  drawTime: string;
  period: RefreshPeriod;
  limitCount: boolean;
  countLimit: number;
  requirement: Requirements;
  languages: Language[];
  prizes: SubObjectives[];
  stock: Stock;
  enabled: boolean;
  nextDraw: string; // Next draw time for the raffle - this is set by the backend and should be readonly field in UI
}

export const defaultRaffle: Raffle = {
  ...defaultElementBase,
  credits: 0,
  active: defaultTimeRange,
  drawTime: formatRFC3339(new Date()),
  period: "all-time",
  limitCount: true,
  countLimit: 1,
  requirement: defaultRequirements,
  languages: [],
  prizes: [],
  enabled: true,
  stock: defaultStock,
  nextDraw: formatRFC3339(new Date()),
};
