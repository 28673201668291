import React, { useState, useEffect } from "react";
import {
  useGetEventsQuery,
  useGetQuizzesQuery,
  useGetSurveysQuery,
} from "@services/api/apiSlice";
import { Event } from "@features/events/Event";
import { Quiz } from "@/features/quizzes/Quiz";
import { Survey } from "@/features/surveys/Survey";
import { ListPicker, ListItem } from "@pickers/listPicker";
import { ItemEdit } from "@pickers/itemEdit";
import { useAccount } from "@/features/accounts/useAccount";
import { MissionObjectives } from "@/features/missions/Mission";
import { SubObjectives } from "@/features/common/Common";
import EventPicker from "@/assets/icons/EventPicker.png";
import QuizPickerIcon from "@/assets/icons/QuizPicker.png";
import SurveyPickerIcon from "@/assets/icons/SurveyPicker.png";
import SingleEntitySelector from "@/components/pickers/SingleEntitySelector";
import { SelectedItem } from "@/components/pickers/Common";
import { isEmpty } from "@/utils/utils";
import { SectionHeader } from "@/components/fields/Display";

interface MissionObjectivesPickerProps {
  objectives: MissionObjectives;
  className?: string;
  onChange: (value: MissionObjectives) => void;
}

const MissionObjectivesPicker: React.FC<MissionObjectivesPickerProps> = ({
  objectives,
  className,
  onChange,
}) => {
  const { account } = useAccount();
  const accountId = account?.account || "";

  // Queries
  const { data: events = [], isLoading: isLoadingEvents } = useGetEventsQuery({
    account: accountId,
  });
  const { data: quizzes = [], isLoading: isLoadingQuizzes } =
    useGetQuizzesQuery({ account: accountId });
  const { data: surveys = [], isLoading: isLoadingSurveys } =
    useGetSurveysQuery({ account: accountId });

  // State
  const [selectedEvents, setSelectedEvents] = useState<SelectedItem[]>([]);
  const [selectedQuiz, setSelectedQuiz] = useState<string>("");
  const [selectedSurvey, setSelectedSurvey] = useState<string>("");

  // Convert SubObjectives and entities to SelectedItems
  const convertToSelectedItems = (
    subObjectives: SubObjectives[],
    entities: Array<Event | Quiz | Survey>
  ): SelectedItem[] => {
    return subObjectives
      .filter((obj) => entities.some((entity) => entity.id === obj.id))
      .map((obj) => {
        const entity = entities.find((e) => e.id === obj.id)!;
        return {
          id: entity.id,
          name: entity.name,
          imgUrl: entity.imgUrl,
          count: obj.count,
        };
      });
  };

  // Initialize selections from objectives
  useEffect(() => {
    if (!isLoadingEvents && objectives.events) {
      setSelectedEvents(convertToSelectedItems(objectives.events, events));
    }
    if (!isLoadingQuizzes && !isEmpty(objectives.quizzId)) {
      setSelectedQuiz(objectives.quizzId);
    }
    if (!isLoadingSurveys && !isEmpty(objectives.surveyId)) {
      setSelectedSurvey(objectives.surveyId);
    }
  }, [
    objectives,
    events,
    quizzes,
    surveys,
    isLoadingEvents,
    isLoadingQuizzes,
    isLoadingSurveys,
  ]);

  // Event handlers
  const handleEventSelect = (item: ListItem) => {
    if (isLoadingEvents) return;

    const selectedEvent = events.find((e) => e.id === item.id);
    if (!selectedEvent) return;

    setSelectedEvents((prev) => {
      const exists = prev.some((e) => e.id === item.id);
      return exists
        ? prev.filter((e) => e.id !== item.id)
        : [...prev, { ...selectedEvent, count: 0 }];
    });

    // Clear other selections when events are selected
    updateObjectives(
      [...selectedEvents, { ...selectedEvent, count: 0 }],
      "",
      ""
    );
  };

  const handleEventDelete = (id: string) => {
    const updatedEvents = selectedEvents.filter((item) => item.id !== id);
    setSelectedEvents(updatedEvents);
    updateObjectives(updatedEvents, selectedQuiz, selectedSurvey);
  };

  const handleEventValueChange = (id: string, value: number) => {
    const updatedEvents = selectedEvents.map((item) =>
      item.id === id ? { ...item, count: value } : item
    );
    setSelectedEvents(updatedEvents);
    updateObjectives(updatedEvents, selectedQuiz, selectedSurvey);
  };

  const handleQuizChange = (id: string | undefined) => {
    setSelectedQuiz(id || "");
    updateObjectives([], id || "", "");
  };

  const handleSurveyChange = (id: string | undefined) => {
    setSelectedSurvey(id || "");
    updateObjectives([], "", id || "");
  };

  // Update objectives
  const updateObjectives = (
    events: SelectedItem[],
    quizId: string,
    surveyId: string
  ) => {
    onChange({
      ...objectives,
      events: events.map((e) => ({
        id: e.id,
        count: e.count,
        currentCount: 0,
      })),
      quizzId: quizId,
      surveyId: surveyId,
      missions: [],
    });
  };

  if (isLoadingEvents || isLoadingQuizzes || isLoadingSurveys) {
    return <div>Loading...</div>;
  }

  // Create event list items
  const eventListItems = events.map((item) => ({
    id: item.id,
    name: item.name,
    disabled: selectedEvents.some((selected) => selected.id === item.id),
  }));

  return (
    <div>
      <SectionHeader title="Objectives" />
      <div className="grid grid-cols-2 gap-4">
        {!selectedQuiz && !selectedSurvey && (
          <>
            <div className="col-start-1">
              <ListPicker
                icon={EventPicker}
                items={eventListItems}
                currentValue={selectedEvents.at(-1)?.id}
                className={className}
                listType="Event"
                onSelect={handleEventSelect}
                mode="select"
              />
            </div>
            <div className="col-start-2 space-y-4">
              {selectedEvents.map((item) => (
                <ItemEdit
                  key={item.id}
                  id={item.id}
                  imgUrl={item.imgUrl}
                  title="Event Name:"
                  name={item.name}
                  countTitle="Steps Required:"
                  count={item.count}
                  onChange={handleEventValueChange}
                  onDelete={handleEventDelete}
                />
              ))}
            </div>
          </>
        )}

        {selectedEvents.length === 0 && !selectedSurvey && (
          <SingleEntitySelector
            entities={quizzes}
            selectedEntity={selectedQuiz}
            isLoading={isLoadingQuizzes}
            className={className}
            entityType="Quiz"
            icon={QuizPickerIcon}
            onChange={handleQuizChange}
          />
        )}

        {selectedEvents.length === 0 && !selectedQuiz && (
          <SingleEntitySelector
            entities={surveys}
            selectedEntity={selectedSurvey}
            isLoading={isLoadingSurveys}
            className={className}
            entityType="Survey"
            icon={SurveyPickerIcon}
            onChange={handleSurveyChange}
          />
        )}
      </div>
    </div>
  );
};

export default MissionObjectivesPicker;
