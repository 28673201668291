import React, { useState, useEffect, useCallback } from "react";
import { useGetAchievementsQuery } from "@services/api/apiSlice";
import { Rewards } from "@/features/common/Common";
import { PointsInput, CreditsInput } from "../fields/Fields";
import { ListPicker, ListItem } from "@pickers/listPicker";
import { ItemEdit } from "@pickers/itemEdit";
import { useAccount } from "@/features/accounts/useAccount";
import AchievementPicker from "@/assets/icons/AchievementPicker.png";
import { SectionHeader } from "@/components/fields/Display";
import { SelectedItem } from "./Common";

interface RewardPickerProps {
  reward: Rewards;
  className?: string;
  onChange: (value: Rewards) => void;
  achievementId?: string;
}

const RewardsPicker: React.FC<RewardPickerProps> = ({
  reward,
  className,
  onChange,
  achievementId = null,
}) => {
  const { account } = useAccount();
  const { data: achievements = [], isLoading } = useGetAchievementsQuery({
    account: account?.account || "",
  });

  const [selectedAchievements, setSelectedAchievements] = useState<
    SelectedItem[]
  >([]);

  // Initialize selected achievements from reward
  useEffect(() => {
    if (!isLoading && achievements.length > 0 && reward.achievements) {
      const selected = reward.achievements
        .filter((item) => achievements.some((ach) => ach.id === item.id))
        .map((item) => {
          const achievement = achievements.find((ach) => ach.id === item.id)!;
          return {
            id: achievement.id,
            name: achievement.name,
            imgUrl: achievement.imgUrl,
            count: item.steps,
          };
        });

      setSelectedAchievements(selected);
    }
  }, [isLoading, achievements, reward.achievements]);

  const updateReward = useCallback(
    (
      newAchievements: SelectedItem[],
      points = reward.points,
      credits = reward.credits
    ) => {
      onChange({
        ...reward,
        points,
        credits,
        achievements: newAchievements.map((item) => ({
          id: item.id,
          steps: item.count,
        })),
      });
    },
    [reward, onChange]
  );

  const handleSelect = useCallback(
    (item: ListItem) => {
      if (isLoading) return;

      const achievement = achievements.find((a) => a.id === item.id);
      if (!achievement) return;

      const newSelection = selectedAchievements.some((a) => a.id === item.id)
        ? selectedAchievements.filter((a) => a.id !== item.id)
        : [
            ...selectedAchievements,
            {
              id: achievement.id,
              name: achievement.name,
              imgUrl: achievement.imgUrl,
              count: 0,
            },
          ];

      setSelectedAchievements(newSelection);
      updateReward(newSelection);
    },
    [isLoading, achievements, selectedAchievements, updateReward]
  );

  const handleDelete = useCallback(
    (id: string) => {
      const newSelection = selectedAchievements.filter(
        (item) => item.id !== id
      );
      setSelectedAchievements(newSelection);
      updateReward(newSelection);
    },
    [selectedAchievements, updateReward]
  );

  const handleStepsChange = useCallback(
    (id: string, count: number) => {
      const newSelection = selectedAchievements.map((item) =>
        item.id === id ? { ...item, count } : item
      );
      setSelectedAchievements(newSelection);
      updateReward(newSelection);
    },
    [selectedAchievements, updateReward]
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <SectionHeader title="Rewards" />
      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-1">
          <PointsInput
            value={reward.points}
            onChange={(points) =>
              updateReward(selectedAchievements, points, reward.credits)
            }
          />
        </div>
        <div className="col-start-2 row-start-1">
          <CreditsInput
            value={reward.credits}
            onChange={(credits) =>
              updateReward(selectedAchievements, reward.points, credits)
            }
          />
        </div>
        <div className="col-start-1 row-start-2">
          <ListPicker
            icon={AchievementPicker}
            items={achievements.map((item) => ({
              id: item.id,
              name: item.name,
              disabled:
                selectedAchievements.some(
                  (selected) => selected.id === item.id
                ) || item.id === achievementId,
            }))}
            currentValue={selectedAchievements.at(-1)?.id}
            className={className}
            listType="Achievement"
            onSelect={handleSelect}
            mode="select"
          />
        </div>
        <div className="col-start-2 row-start-2 space-y-4">
          {selectedAchievements.map((item) => (
            <ItemEdit
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              title="Achievement Name:"
              name={item.name}
              countTitle="Steps Granted:"
              count={item.count}
              onChange={handleStepsChange}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RewardsPicker;
