import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetLeaderboardsQuery,
  useAddLeaderboardMutation, // add an leaderboard
  useUpdateLeaderboardMutation, // update an leaderboard
} from "@services/api/apiSlice";

import { CategoryInput, TagsInput } from "@fields/Fields";

import { Divider, SectionHeader } from "@fields/Display";

import RefreshPeriodSelector from "@fields/Refresh";

import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "../../components/forms/BaseEntityForm";

import RequirementPicker from "@pickers/requirementsPicker";
import DateRangePicker from "@pickers/daterangePicker";

import { Leaderboard, defaultLeaderboard } from "./Leaderboard";
import LeaderboardUnitSelector from "./leaderboardUnits";
import LeaderboardElementSelector from "./leaderboardElements";
import MissionsPicker from "./missionsPicker";

const LeaderboardPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: leaderboards = [], isLoading } = useGetLeaderboardsQuery({
    account: account?.account || "",
  });
  const [addNewLeaderboard] = useAddLeaderboardMutation();
  const [updateLeaderboard] = useUpdateLeaderboardMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const leaderboardToEdit = leaderboards.find(
        (leaderboard) => leaderboard.id === id
      );
      if (
        !account ||
        (leaderboardToEdit && leaderboardToEdit.account !== account.account)
      ) {
        navigate("/leaderboards");
      }
    }
  }, [leaderboards, id, navigate, account, isNew]);

  const leaderboardToEdit = leaderboards.find(
    (leaderboard) => leaderboard.id === id
  );
  const currentLeaderboard = isNew
    ? { ...defaultLeaderboard, account: account?.account || "" }
    : leaderboardToEdit || defaultLeaderboard;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Leaderboard>
      entityName="Leaderboard"
      entityPath="leaderboards/list"
      entity={currentLeaderboard}
      isNew={isNew}
      defaultEntity={defaultLeaderboard}
      descriptionPlaceholder="Give your players more details about the Leaderboard."
      addEntity={(entity) => addNewLeaderboard(entity).unwrap()}
      updateEntity={(entity) => updateLeaderboard(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
          </div>
          <Divider />
          <SectionHeader title="Leaderboard Management" />
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <DateRangePicker
              value={entity.active}
              onChange={(value) => updateField("active", value)}
            />
          </div>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-4">
            <RefreshPeriodSelector
              value={entity.period}
              onChange={(value) => {
                updateField("period", value);
              }}
            />
          </div>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <LeaderboardUnitSelector
              value={entity.units}
              onChange={(units) => updateField("units", units)}
            />
            <LeaderboardElementSelector
              value={entity.element}
              onChange={(element) => updateField("element", element)}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <MissionsPicker
              drivenBy={entity.drivenBy}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(drivenBy) => updateField("drivenBy", drivenBy)}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <RequirementPicker
              requirement={entity.requirement}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(requirement) =>
                updateField("requirement", requirement)
              }
            />
          </div>
        </>
      )}
    />
  );
};

export default LeaderboardPage;
