import { ElementBase, defaultElementBase } from "@features/common/Common";

export interface Cost {
  cost: number;
  min: number;
  max: number;
}

export interface Account extends ElementBase {
  account: string;
  name: string;
  description: string;
  apikey: string;
  imgUrl: string;
  isAvailable: boolean;
  imageFolder: string;
  isDevAccount: boolean;
  isFreeAccount: boolean;
  hasCustomPrizing: boolean;
  prizingModel: Cost[];
}

export interface AccountContextType {
  account: Account | null;
  setAccount: (account: Account | null) => void;
}

export const defaultAccount: Account = {
  ...defaultElementBase,
  isDevAccount: true,
  isFreeAccount: false,
  apikey: "",
  imageFolder: "",
  hasCustomPrizing: false,
  prizingModel: [],
};

export type AccountTypes = "paid" | "free" | "test";
