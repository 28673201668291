import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils/utils";

// Base styles that will always be applied
const baseStyles =
  "rounded-full px-2 py-4 mt-6 border-solid border-2 shadow transition-colors";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-2xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: `${baseStyles} bg-primary-500 text-primary-foreground hover:bg-primary-200 hover:text-primary-500 border-primary-500`,
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        custom: baseStyles, // Apply base styles to custom variant
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-2xl px-3 text-xs",
        lg: "h-10 rounded-2xl px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  color?: string;
  hoverColor?: string;
  textColor?: string;
  hoverTextColor?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      color,
      hoverColor,
      textColor,
      hoverTextColor,
      style,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    const customStyle = color
      ? {
          ...style,
          backgroundColor: color,
          borderColor: color,
          color: textColor || "white",
          "--hover-bg-color": hoverColor || color,
          "--hover-text-color": hoverTextColor || textColor || "white",
        }
      : style;

    const customClassName = color
      ? cn(
          buttonVariants({ variant: "custom", size, className }),
          "hover:bg-[var(--hover-bg-color)] hover:text-[var(--hover-text-color)]"
        )
      : cn(buttonVariants({ variant, size, className }));

    return (
      <Comp
        className={customClassName}
        ref={ref}
        style={customStyle}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
