import React from "react";

interface DividerProps {
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({ className }) => (
  <div
    className={`col-span-2 my-4 border border-primary-200 border-t-0 border-r-0 border-l-0 ${
      className || ""
    }`}
  />
);

interface SectionHeaderProps {
  title: string;
  className?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  className,
}) => <h2 className={`text-1xl font-bold mb-4 ${className || ""}`}>{title}</h2>;
