import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetAchievementsQuery,
  useAddAchievementMutation, // add an mission
  useUpdateAchievementMutation, // update an mission
} from "@services/api/apiSlice";

import { CategoryInput, TagsInput, StepInput } from "@fields/Fields";

import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "../../components/forms/BaseEntityForm";

import RewardsPicker from "@pickers/rewardsPicker";
import RequirementPicker from "@pickers/requirementsPicker";

import { Achievement, defaultAchievement } from "./Achievement";
import { Divider } from "@fields/Display";

const AchievementPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: achievements = [], isLoading } = useGetAchievementsQuery({
    account: account?.account || "",
  });
  const [addNewAchievement] = useAddAchievementMutation();
  const [updateAchievement] = useUpdateAchievementMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const achievementToEdit = achievements.find(
        (achievement) => achievement.id === id
      );
      if (
        !account ||
        (achievementToEdit && achievementToEdit.account !== account.account)
      ) {
        navigate("/achievements");
      }
    }
  }, [achievements, id, navigate, account, isNew]);

  const achievementToEdit = achievements.find(
    (achievement) => achievement.id === id
  );
  const currentAchievement = isNew
    ? { ...defaultAchievement, account: account?.account || "" }
    : achievementToEdit || defaultAchievement;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Achievement>
      entityName="Achievement"
      entityPath="achievements/list"
      entity={currentAchievement}
      isNew={isNew}
      defaultEntity={defaultAchievement}
      descriptionPlaceholder="Tell your players all about the Achievement and what they need to do to unlock it."
      addEntity={(entity) => addNewAchievement(entity).unwrap()}
      updateEntity={(entity) => updateAchievement(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-3 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
            <StepInput
              value={entity.steps}
              required
              onChange={(steps) => updateField("steps", steps)}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <RewardsPicker
              reward={entity.reward}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(reward) => updateField("reward", reward)}
              achievementId={entity.id}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <RequirementPicker
              requirement={entity.requirement}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(requirement) =>
                updateField("requirement", requirement)
              }
            />
          </div>
        </>
      )}
      additionalValidation={(entity, errors) => {
        // Add any achievement specific validation here
        if (entity.steps < 1) {
          errors.steps = "Steps must be 1 or higher";
        }
      }}
    />
  );
};

export default AchievementPage;
