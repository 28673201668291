import React, { useState, useEffect, useCallback } from "react";
import { useGetPrizesQuery } from "@services/api/apiSlice";
import { SubObjectives } from "@/features/common/Common";
import { ListPicker, ListItem } from "@pickers/listPicker";
import { ItemEdit } from "@pickers/itemEdit";
import { useAccount } from "@/features/accounts/useAccount";
import PrizePickerIcon from "@/assets/icons/Prizepicker.png";
import { SectionHeader } from "@/components/fields/Display";
import { SelectedItem } from "./Common";

interface PrizePickerProps {
  rafflePrizes: SubObjectives[];
  className?: string;
  onChange: (value: SubObjectives[]) => void;
}

const PrizePicker: React.FC<PrizePickerProps> = ({
  rafflePrizes,
  className,
  onChange,
}) => {
  const { account } = useAccount();
  const { data: prizes = [], isLoading } = useGetPrizesQuery({
    account: account?.account || "",
  });

  const [selectedPrizes, setSelectedPrizes] = useState<SelectedItem[]>([]);

  useEffect(() => {
    if (!isLoading && prizes.length > 0 && rafflePrizes) {
      const selected = rafflePrizes
        .filter((item) => prizes.some((prize) => prize.id === item.id))
        .map((item) => {
          const prize = prizes.find((p) => p.id === item.id)!;
          return {
            id: prize.id,
            name: prize.name,
            imgUrl: prize.imgUrl,
            count: item.count,
          };
        });

      setSelectedPrizes(selected);
    }
  }, [isLoading, prizes, rafflePrizes]);

  const updateSelection = useCallback(
    (newSelection: SelectedItem[]) => {
      setSelectedPrizes(newSelection);
      onChange(
        newSelection.map((item) => ({
          id: item.id,
          count: item.count,
          currentCount: 0,
        }))
      );
    },
    [onChange]
  );

  const handleSelect = useCallback(
    (item: ListItem) => {
      if (isLoading) return;

      const prize = prizes.find((p) => p.id === item.id);
      if (!prize) return;

      const newSelection = selectedPrizes.some((p) => p.id === item.id)
        ? selectedPrizes.filter((p) => p.id !== item.id)
        : [
            ...selectedPrizes,
            {
              id: prize.id,
              name: prize.name,
              imgUrl: prize.imgUrl,
              count: 0,
            },
          ];

      updateSelection(newSelection);
    },
    [isLoading, prizes, selectedPrizes, updateSelection]
  );

  const handleDelete = useCallback(
    (id: string) => {
      updateSelection(selectedPrizes.filter((item) => item.id !== id));
    },
    [selectedPrizes, updateSelection]
  );

  const handleCountChange = useCallback(
    (id: string, count: number) => {
      updateSelection(
        selectedPrizes.map((item) =>
          item.id === id ? { ...item, count } : item
        )
      );
    },
    [selectedPrizes, updateSelection]
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <SectionHeader title="Prizes" />
      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-2">
          <ListPicker
            icon={PrizePickerIcon}
            items={prizes.map((item) => ({
              id: item.id,
              name: item.name,
              disabled: selectedPrizes.some((p) => p.id === item.id),
            }))}
            currentValue={selectedPrizes.at(-1)?.id}
            className={className}
            listType="Prize"
            onSelect={handleSelect}
            mode="select"
            placeholder="Add Prize(s)"
          />
        </div>
        <div className="col-start-2 row-start-2 space-y-4">
          {selectedPrizes.map((item) => (
            <ItemEdit
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              title="Prize Name:"
              name={item.name}
              countTitle="No. Units:"
              count={item.count}
              onChange={handleCountChange}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrizePicker;
