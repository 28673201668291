import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetStreaksQuery,
  useAddStreakMutation, // add an mission
  useUpdateStreakMutation, // update an mission
} from "@services/api/apiSlice";

import {
  CategoryInput,
  TagsInput,
  PriorityInput,
  RestrictedCountInput,
  IsLimitInput,
} from "@fields/Fields";
import { Divider, SectionHeader } from "@fields/Display";
import RefreshPeriodSelector from "@fields/Refresh";

import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "@/components/forms/BaseEntityForm";

import RewardsPicker from "@pickers/rewardsPicker";
import StreakObjectivesPicker from "./streakObjectivesPicker";
import DateRangePicker from "@pickers/daterangePicker";

import { Streak, defaultStreak } from "./Streak";

const StreakPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: streaks = [], isLoading } = useGetStreaksQuery({
    account: account?.account || "",
  });
  const [addNewStreak] = useAddStreakMutation();
  const [updateStreak] = useUpdateStreakMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const streakToEdit = streaks.find((streak) => streak.id === id);
      if (
        !account ||
        (streakToEdit && streakToEdit.account !== account.account)
      ) {
        navigate("/streaks");
      }
    }
  }, [streaks, id, navigate, account, isNew]);

  const streakToEdit = streaks.find((streak) => streak.id === id);
  const currentStreak = isNew
    ? { ...defaultStreak, account: account?.account || "" }
    : streakToEdit || defaultStreak;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Streak>
      entityName="Streak"
      entityPath="streaks/list"
      entity={currentStreak}
      isNew={isNew}
      defaultEntity={defaultStreak}
      descriptionPlaceholder="Tell your players all about the Streaks and what they need to do to keep it alive or complete it."
      addEntity={(entity) => addNewStreak(entity).unwrap()}
      updateEntity={(entity) => updateStreak(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-3 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
            <PriorityInput
              value={entity.priority}
              onChange={(value) => updateField("priority", value)}
            />
          </div>
          <Divider />
          <SectionHeader title="Streak Availability" />
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <DateRangePicker
              value={entity.active}
              onChange={(value) => updateField("active", value)}
            />
          </div>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-4">
            <RefreshPeriodSelector
              value={entity.period}
              onChange={(value) => {
                console.log(value);
                updateField("period", value);
              }}
            />

            <div></div>
            <div className="flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.countLimit}
                onChange={(value) => updateField("countLimit", value)}
                placeholder="0"
                disabled={!entity.limitCount}
                label="Fixed Streak Length"
              />
            </div>
            <div className="flex items-stretch h-9 mt-auto">
              <IsLimitInput
                value={entity.limitCount}
                onChange={(value) => updateField("limitCount", value)}
              />
            </div>
          </div>
          <Divider />
          <div className="col-span-2">
            <StreakObjectivesPicker
              objectives={entity.objectives}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(objectives) => updateField("objectives", objectives)}
            />
          </div>
          <Divider />
          <div className="col-span-2">
            <RewardsPicker
              reward={entity.reward}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(reward) => updateField("reward", reward)}
            />
          </div>
          <Divider />
        </>
      )}
      additionalValidation={(entity, errors) => {
        // Add any streak specific validation here
        if (entity.limitCount && !entity.countLimit) {
          errors.countLimit = "Count limit is required when limit is enabled";
        }
      }}
    />
  );
};

export default StreakPage;
