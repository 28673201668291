import React, { useMemo } from "react";
import { PageLayout } from "@layout/page-layout";
import { useGetAccountsQuery } from "@services/api/apiSlice";
import Loader from "@elements/loader";
import { useAccount } from "./useAccount";
import EntityList from "@/components/lists/entity-list";
import { usePermissions } from "@/features/permissions/usePermissions";
import { ActionButton } from "@buttons/actionButton";

const AccountsPage: React.FC = () => {
  useAccount();
  const { canWriteAdmin } = usePermissions();

  const { data: accounts = [], error, isLoading } = useGetAccountsQuery();

  let content: React.ReactNode;

  const sortedAccounts = useMemo(() => {
    const sortedAccounts = accounts.slice();
    // Sort accounts by name
    sortedAccounts.sort((a, b) => a.name.localeCompare(b.name));
    return sortedAccounts;
  }, [accounts]);

  if (isLoading) {
    <Loader />;
  } else if (error) {
    content = <div>Error: {error.toString()}</div>;
  } else {
    content = (
      <PageLayout>
        <div className="flex max-w-full  p-6 justify-items items-center">
          <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
            Accounts
          </h1>
          {canWriteAdmin() && (
            <div className="flex flex-row gap-4">
              <ActionButton to="/accounts/new">Create Account</ActionButton>
            </div>
          )}
        </div>
        <section className="accounts-list">
          <EntityList entities={sortedAccounts} entityType="accounts" />
        </section>
      </PageLayout>
    );
  }

  return <>{content}</>;
};

export default AccountsPage;
