import { defaultElementBase, ElementBase } from "@features/common/Common";

export interface Team extends ElementBase {
  points: number;
  credits: number;
  badges: string[];
}

export const defaultTeam: Team = {
  ...defaultElementBase,

  credits: 0,
  points: 0,
  badges: [],
};
