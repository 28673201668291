// AccountPage.tsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetAccountsQuery,
  useAddAccountMutation,
  useCopyAccountMutation,
  useUpdateAccountMutation,
  useUploadImageMutation,
} from "@services/api/apiSlice";

import { Link } from "@components/ui/link";
import { CommonInput } from "@fields/Fields";
import { Button } from "@components/ui/button";
import { Label } from "@components/ui/label";
import { PageLayout } from "@layout/page-layout";
import { Account, defaultAccount, AccountTypes } from "./Account";
import ImagePicker from "@pickers/imagePicker";
import { usePermissions } from "@/features/permissions/usePermissions";

import { useApiToasts } from "@/utils/toasts";

import {
  NameInput,
  DescriptionInput,
  IdInput,
  IsAvailableInput,
} from "@fields/Fields";
import AccountTypeSelector from "./accountTypes";

import { isEmpty } from "@/utils/utils";

const DEFAULT_IMAGE = import.meta.env.VITE_APP_DEFAULT_IMAGE;

const typeOfAccount = (account: Account): AccountTypes => {
  if (account.isDevAccount) return "test";
  if (account.isFreeAccount) return "free";
  return "paid";
};

const AccountPage: React.FC = () => {
  const { canWriteAdmin } = usePermissions();
  const { showError, showSuccess } = useApiToasts();
  const { account: accountId } = useParams<{ account: string }>();
  const navigate = useNavigate();

  const { data: accounts = [], isLoading } = useGetAccountsQuery();
  const [copyToAccount, setCopyToAccount] = useState("");
  const [account, setAccount] = useState<Account>(defaultAccount);
  const [errors, setErrors] = useState<Partial<Record<keyof Account, string>>>(
    {}
  );
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [uploadImage] = useUploadImageMutation();
  const [addNewAccount] = useAddAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [copyAccount] = useCopyAccountMutation();
  const [accountType, setAccountTypeX] = useState<AccountTypes>("paid");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const isNew = accountId === "new";

  useEffect(() => {
    if (!isNew) {
      const accountToEdit = accounts.find((acc) => acc.account === accountId);
      if (!accountToEdit) {
        navigate("/accounts");
        return;
      }

      setAccount(accountToEdit);
      setAccountTypeX(typeOfAccount(accountToEdit));
    }
  }, [accounts, navigate, isNew, accountId]);

  useEffect(() => {
    setIsReadOnly(!canWriteAdmin());
  }, [canWriteAdmin]);

  const updateField = <K extends keyof Account>(
    field: K,
    value: Account[K]
  ) => {
    setAccount((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const setAccountType = (accountType: AccountTypes) => {
    if (accountType === "paid") {
      setAccount((prev) => ({
        ...prev,
        isDevAccount: false,
        isFreeAccount: false,
      }));
    } else if (accountType === "free") {
      setAccount((prev) => ({
        ...prev,
        isDevAccount: false,
        isFreeAccount: true,
      }));
    } else if (accountType === "test") {
      setAccount((prev) => ({
        ...prev,
        isDevAccount: true,
        isFreeAccount: false,
      }));
    }
  };

  const saveImage = async (
    file: File | null,
    currentImgUrl: string
  ): Promise<string> => {
    if (!file || !account?.account) return currentImgUrl;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const result = await uploadImage({
        formData,
        account: account.account,
      }).unwrap();
      return result.path;
    } catch (error) {
      showError(error);
      return currentImgUrl;
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    let entityWithImage;
    try {
      if (!isNew) {
        const imagePath = await saveImage(file, account.imgUrl);
        entityWithImage = { ...account, imgUrl: imagePath };
      } else {
        entityWithImage = { ...account, imgUrl: DEFAULT_IMAGE };
      }

      if (isNew) {
        await addNewAccount(entityWithImage);
        showSuccess(`Added account ${entityWithImage.account}`);
      } else {
        await updateAccount(entityWithImage);
        showSuccess(`Updated account ${entityWithImage.account}`);
      }

      navigate(`/accounts`);
    } catch (err) {
      showError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCopy = async () => {
    if (!accountId) return;
    setIsCopying(true);
    try {
      await copyAccount({
        fromAccount: accountId,
        toAccount: copyToAccount,
      }).unwrap();
    } catch (error) {
      showError(error);
    }
    setIsCopying(false);
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof Account, string>> = {};

    if (!account.account.trim()) newErrors.id = "Account ID is required";
    if (!account.name.trim()) newErrors.name = "Name is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImagePicked = (file: File) => {
    if (file) {
      setFile(file);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout>
      <div>
        <Link to="/accounts" variant="withIcon">
          Back
        </Link>
        <form onSubmit={handleSave} className="mx-4">
          <div className="p-5 bg-neutral-100 rounded-3xl">
            <Label className="text-2xl font-bold my-6">
              {account.name ? account.name : `Create new Account`}
            </Label>

            <div className="grid grid-cols-2 mt-4 gap-4">
              <div className="col-span-1">
                <IsAvailableInput
                  value={account.isAvailable}
                  onChange={(value) => updateField("isAvailable", value)}
                  disabled={isReadOnly}
                />
              </div>
              <div className="col-span-1" />

              <div className="col-span-1">
                <NameInput
                  entity_name="Account"
                  value={account.name}
                  required
                  onChange={(value) => updateField("name", value)}
                  placeholder={`Your Account needs a name`}
                  error={errors.name}
                  disabled={isReadOnly}
                />
              </div>

              <div className="col-span-1">
                <IdInput
                  entity_name="Account"
                  value={account.account}
                  required
                  onChange={(value) => updateField("account", value)}
                  placeholder="And a unique Account ID"
                  disabled={!isNew || isReadOnly}
                  error={errors.account}
                />
              </div>

              <div className="col-span-1">
                <DescriptionInput
                  value={account.description}
                  onChange={(value) => updateField("description", value)}
                  placeholder={"Add a description for the Account"}
                  disabled={isReadOnly}
                />
              </div>

              <div className="col-span-1">
                <ImagePicker
                  path={account.imgUrl}
                  id={account.account}
                  onImagePicked={handleImagePicked}
                  size="small"
                  disabled={isReadOnly}
                />
              </div>

              <div className="col-span-2">
                <AccountTypeSelector
                  value={accountType}
                  disabled={isReadOnly}
                  onChange={(value) => {
                    setAccountTypeX(value);
                    setAccountType(value);
                  }}
                />
              </div>

              <CommonInput
                id="imagefolder"
                entity_name="Image Folder"
                required
                helpText="Folder name where gamification images will be stored."
                value={account.imageFolder}
                onChange={(value) => updateField("imageFolder", value)}
                disabled={isReadOnly}
              />

              <CommonInput
                id="apikey"
                entity_name="API Key"
                value={account.apikey}
                disabled={true}
                helpText="API key generared by the system."
                onChange={() => {}}
              />

              <div className="col-span-2 grid grid-cols-2 md:grid-cols-2  mt-4 gap-4">
                <CommonInput
                  id="copyto"
                  entity_name="Copy this account's elements to another"
                  value={copyToAccount}
                  disabled={isReadOnly}
                  helpText="Account ID to which gamification elements will be copied to."
                  onChange={(value) => {
                    setCopyToAccount(value);
                  }}
                />

                <div className="mt-auto">
                  <Button
                    className="uppercase py-6 px-16"
                    type="submit"
                    color="#EAB308"
                    hoverColor="#FCD34D"
                    onClick={handleCopy}
                    disabled={isCopying || isReadOnly}
                  >
                    {isCopying ? "Copying..." : "Copy"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4 mb-16">
            <Button
              className="uppercase py-6 px-16"
              type="submit"
              disabled={
                isReadOnly ||
                isSubmitting ||
                isEmpty(account.imageFolder) ||
                isEmpty(account.account) ||
                isEmpty(account.name)
              }
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default AccountPage;
