import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { Label } from "@/components/ui/label";
import FieldHelp from "@/components/elements/fieldHelp";

const DEFAULT_IMAGE = import.meta.env.VITE_APP_DEFAULT_IMAGE;

interface InputWrapperProps {
  name: string;
  label: string;
  helpText?: string;
  required?: boolean;
  error?: string;
  children: React.ReactNode;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  name,
  label,
  helpText,
  required,
  error,
  children,
}) => (
  <div>
    <div className="flex justify-between items-center m-2">
      <Label className="text-sm" htmlFor={name}>
        {label && (required ? label : label)}
      </Label>
      <FieldHelp helpText={helpText} />
    </div>
    {children}
    {error && <span style={{ color: "red", fontSize: "0.8rem" }}>{error}</span>}
  </div>
);

const imageUrl = (file: File) => {
  return file && file instanceof File
    ? URL.createObjectURL(file)
    : DEFAULT_IMAGE;
};

const smallImageSize = 72;
const normalImageSize = 122;

interface ImagePickerProps {
  path: string;
  onImagePicked: (file: File) => void;
  id: string;
  size: string;
  disabled?: boolean;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  path,
  onImagePicked,
  id,
  size,
  disabled = false,
}) => {
  const [imageSource, setImageSource] = useState<File | null>(null);
  const [imgPath, setImgPath] = useState(DEFAULT_IMAGE);
  const hiddenFileInput = useRef(null);

  const inputId = `contained-button-file-${id}`;
  const isSmallImage = size === "small";
  const imageSize = isSmallImage ? smallImageSize : normalImageSize;
  const isDefaultImage = imgPath === DEFAULT_IMAGE;

  useEffect(() => {
    if (!imageSource) return;
    onImagePicked(imageSource);
    return () => {
      //cleanup;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgPath]);

  useEffect(() => {
    if (path != null && path !== "") {
      setImgPath(path);
    }
  }, [path]);

  const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageSource(file);
      setImgPath(imageUrl(file));
    }
  };

  return (
    <InputWrapper
      name="image"
      label="Add Image"
      required={false}
      helpText="Click and upload an image that you can use in the front-end."
    >
      <fieldset className="flex flex-col items-center justify-center rounded-2xl border text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 w-full border-primary-200 focus:ring-0 focus:outline-none focus:bg-white focus:border-primary-500 bg-white">
        <div className="flex flex-col items-center justify-center">
          <div></div>
          <input
            accept="image/*"
            id={inputId}
            multiple={false}
            type="file"
            ref={hiddenFileInput}
            onChange={handleCapture}
            style={{ display: "none" }}
            disabled={disabled}
          />
          <div className="flex flex-col items-center justify-center">
            <label htmlFor={inputId}>
              <div className="w-20 h-20 md:w-[200px] md:h-[200px] relative overflow-hidden">
                <img
                  src={imgPath}
                  height={imageSize}
                  width="100%"
                  alt="Selected image"
                  className={`
                    absolute top-0 left-0 
                    w-full h-full 
                    object-cover 
                     p-[15%]
                    ${
                      disabled
                        ? "opacity-50 grayscale cursor-not-allowed"
                        : "opacity-100 cursor-pointer"
                    }
                  `}
                />
                {isDefaultImage && (
                  <span className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-blue-500 text-sm font-bold">
                    Add Image
                  </span>
                )}
              </div>
            </label>
          </div>
        </div>
      </fieldset>
    </InputWrapper>
  );
};

export default ImagePicker;
