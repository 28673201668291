import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetPrizesQuery,
  useAddPrizeMutation, // add an mission
  useUpdatePrizeMutation, // update an mission
} from "@services/api/apiSlice";

import {
  CategoryInput,
  TagsInput,
  RestrictedCountInput,
  IsLimitInput,
} from "@fields/Fields";
import { Divider, SectionHeader } from "@fields/Display";
import RefreshPeriodSelector from "@fields/Refresh";

import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "../../components/forms/BaseEntityForm";

import RequirementPicker from "@pickers/requirementsPicker";
import DateRangePicker from "@pickers/daterangePicker";

import { Prize, defaultPrize } from "./Prize";

const PrizePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: prizes = [], isLoading } = useGetPrizesQuery({
    account: account?.account || "",
  });
  const [addNewPrize] = useAddPrizeMutation();
  const [updatePrize] = useUpdatePrizeMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const prizeToEdit = prizes.find((prize) => prize.id === id);
      if (
        !account ||
        (prizeToEdit && prizeToEdit.account !== account.account)
      ) {
        navigate("/prizes");
      }
    }
  }, [prizes, id, navigate, account, isNew]);

  const prizeToEdit = prizes.find((prize) => prize.id === id);
  const currentPrize = isNew
    ? { ...defaultPrize, account: account?.account || "" }
    : prizeToEdit || defaultPrize;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Prize>
      entityName="Prize"
      entityPath="prizes/list"
      entity={currentPrize}
      isNew={isNew}
      defaultEntity={defaultPrize}
      descriptionPlaceholder="Tell your players all about the Prize and what they need to do to complete it"
      addEntity={(entity) => addNewPrize(entity).unwrap()}
      updateEntity={(entity) => updatePrize(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
          </div>
          <Divider />
          <SectionHeader title="Prize Management" />
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <DateRangePicker
              value={entity.active}
              onChange={(value) => updateField("active", value)}
            />
          </div>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-4">
            <RefreshPeriodSelector
              value={entity.period}
              onChange={(value) => {
                console.log(value);
                updateField("period", value);
              }}
            />
            <div></div>
            <div className="flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.stockCount}
                onChange={(stockCount) => updateField("stockCount", stockCount)}
                placeholder="0"
                disabled={!entity.limitStock}
                label="Limit Stock Levels"
              />
            </div>
            <div className="flex items-stretch h-9 mt-auto">
              <IsLimitInput
                value={entity.limitStock}
                onChange={(limitStock) => updateField("limitStock", limitStock)}
              />
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-3 md:grid-cols-4 gap-4">
            <div className="col-span-2 flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.credits}
                onChange={(credits) => updateField("credits", credits)}
                placeholder="0"
                label="Credits to Purchase"
              />
            </div>

            <div className="flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.countLimit}
                onChange={(value) => updateField("countLimit", value)}
                placeholder="0"
                disabled={!entity.limitCount}
                label="Limit Player Purchases"
              />
            </div>
            <div className="flex items-stretch h-9 mt-auto">
              <IsLimitInput
                value={entity.limitCount}
                onChange={(value) => updateField("limitCount", value)}
              />
            </div>
          </div>
          <Divider />
          <div className="col-span-2">
            <RequirementPicker
              requirement={entity.requirement}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(requirement) =>
                updateField("requirement", requirement)
              }
            />
          </div>
        </>
      )}
      additionalValidation={(entity, errors) => {
        // Add any mission specific validation here
        if (entity.limitCount && !entity.countLimit) {
          errors.countLimit = "Count limit is required when limit is enabled";
        }
      }}
    />
  );
};

export default PrizePage;
