import {
  Rewards,
  defaultRewards,
  defaultElementBase,
  ElementBase,
} from "@features/common/Common";

export type SurveyType = "basic" | "immediate";
export type SurveyQuestionType = "single" | "multi";

export interface SurveyChoice {
  id: string; // id for the survey choice is required. Set by backend if not provided
  imgUrl: string;
  text: string; // text for the survey choice is required
}

export interface SurveyQuestionSchema {
  id: string; // Survey id - if not set, will be set by backend. Need to be unique for the survey
  type: SurveyQuestionType;
  text: string; // Text for the survey question - required
  imgUrl: string;
  weight: number; // Weight of the question
  choices: SurveyChoice[];
}

export interface Survey extends ElementBase {
  type: SurveyType;
  isShuffleRequired: boolean;
  reward: Rewards;
  questions: SurveyQuestionSchema[];
}

export const defaultSurvey: Survey = {
  ...defaultElementBase,
  type: "basic",
  isShuffleRequired: true,
  reward: defaultRewards,
  questions: [],
};
