import React from "react";
import { Label } from "@/components/ui/label";
import RadioButtonRow from "@/components/radiobuttons/RadioButtonRow";
import { LeaderboardUnits } from "@features/common/Common";

interface LeaderboardUnitSelectorProps {
  value: LeaderboardUnits;
  onChange: (value: LeaderboardUnits) => void;
}

const LeaderboardUnitSelector: React.FC<LeaderboardUnitSelectorProps> = ({
  value,
  onChange,
}) => {
  const options: { label: string; value: LeaderboardUnits }[] = [
    { label: "Points", value: "points" },
    { label: "Credits", value: "credits" },
  ];

  return (
    <div className="flex flex-col justify-end w-full">
      <Label htmlFor="refresh-period" className="text-sm mb-1.5">
        Units in Use
      </Label>
      <div className="h-9 flex items-center">
        <RadioButtonRow<LeaderboardUnits>
          name="refresh-period"
          value={value}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
};

export default LeaderboardUnitSelector;
