import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetRafflesQuery,
  useAddRaffleMutation,
  useUpdateRaffleMutation,
} from "@services/api/apiSlice";

import {
  CategoryInput,
  TagsInput,
  RestrictedCountInput,
  IsLimitInput,
} from "@fields/Fields";
import { Divider, SectionHeader } from "@fields/Display";
import RefreshPeriodSelector from "@fields/Refresh";

import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "../../components/forms/BaseEntityForm";

import RequirementPicker from "@pickers/requirementsPicker";
import PrizePicker from "@pickers/prizePicker";
import DateRangePicker from "@pickers/daterangePicker";

import { Raffle, defaultRaffle } from "./Raffle";
import DatePicker from "@/components/pickers/datepicker";

const RafflePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: raffles = [], isLoading } = useGetRafflesQuery({
    account: account?.account || "",
  });
  const [addNewRaffle] = useAddRaffleMutation();
  const [updateRaffle] = useUpdateRaffleMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const raffleToEdit = raffles.find((prize) => prize.id === id);
      if (
        !account ||
        (raffleToEdit && raffleToEdit.account !== account.account)
      ) {
        navigate("/raffles");
      }
    }
  }, [raffles, id, navigate, account, isNew]);

  const raffleToEdit = raffles.find((prize) => prize.id === id);
  const currentRaffle = isNew
    ? { ...defaultRaffle, account: account?.account || "" }
    : raffleToEdit || defaultRaffle;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Raffle>
      entityName="Raffle"
      entityPath="raffles/list"
      entity={currentRaffle}
      isNew={isNew}
      defaultEntity={defaultRaffle}
      descriptionPlaceholder="Give your players more details about the Raffle like what prizes it includes and when it will be drawn, etc."
      addEntity={(entity) => addNewRaffle(entity).unwrap()}
      updateEntity={(entity) => updateRaffle(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
          </div>
          <Divider />
          <SectionHeader title="Raffle Management" />
          <div className="col-span-2">
            <PrizePicker
              rafflePrizes={entity.prizes}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(prizes) => updateField("prizes", prizes)}
            />
          </div>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <DateRangePicker
              value={entity.active}
              onChange={(value) => updateField("active", value)}
            />
          </div>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <RefreshPeriodSelector
              value={entity.period}
              onChange={(value) => {
                updateField("period", value);
              }}
            />
            <DatePicker
              label="Draw Date"
              value={entity.drawTime}
              onChange={(drawTime) =>
                updateField("drawTime", drawTime ? drawTime.toISOString() : "")
              }
            />
            <div></div>
          </div>
          <div className="col-span-2 grid grid-cols-3 md:grid-cols-4 gap-4">
            <div className="col-span-2 flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.credits}
                onChange={(credits) => updateField("credits", credits)}
                placeholder="0"
                label="Credits to Purchase"
              />
            </div>

            <div className="flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.countLimit}
                onChange={(value) => updateField("countLimit", value)}
                placeholder="0"
                disabled={!entity.limitCount}
                label="Limit Player Entries"
              />
            </div>
            <div className="flex items-stretch h-9 mt-auto">
              <IsLimitInput
                value={entity.limitCount}
                onChange={(value) => updateField("limitCount", value)}
              />
            </div>
          </div>
          <Divider />
          <div className="col-span-2">
            <RequirementPicker
              requirement={entity.requirement}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(requirement) =>
                updateField("requirement", requirement)
              }
            />
          </div>
        </>
      )}
      additionalValidation={(entity, errors) => {
        // Add any mission specific validation here
        if (entity.limitCount && !entity.countLimit) {
          errors.countLimit = "Count limit is required when limit is enabled";
        }
      }}
    />
  );
};

export default RafflePage;
