import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

import type { Event } from "@features/events/Event";
import type { Account } from "@features/accounts/Account";
import type { Mission } from "@features/missions/Mission";
import type { Achievement } from "@features/achievements/Achievement";
import type { Level } from "@features/levels/Level";
import type { Leaderboard } from "@features/leaderboards/Leaderboard";
import type { Mysterybox } from "@features/mysteryboxes/Mysterybox";
import type { Player } from "@features/players/Player";
import type { Prize } from "@features/prizes/Prize";
import type { Quiz } from "@features/quizzes/Quiz";
import type { Raffle } from "@features/raffles/Raffle";
import type { Rule } from "@features/rules/Rule";
import type { Streak } from "@features/streaks/Streak";
import type { Survey } from "@features/surveys/Survey";
import type { Team } from "@features/teams/Team";
import type { Analytics } from "@/features/analytics/Analytics";

// We'll create a function to get the token
let getToken: () => Promise<string>;

export const setAuthToken = (tokenGetter: () => Promise<string>) => {
  getToken = tokenGetter;
};

const defaultQuery = {
  limit: 500,
  skip: 0,
  availability: "all",
  timeframe: "all",
  player: "",
};

interface QueryParams {
  account: string;
  limit?: number;
  skip?: number;
  availability?: string;
  timeframe?: string;
  player?: string;
}

interface PlayersQueryParams {
  account: string;
  limit?: number;
  skip?: number;
  category?: string;
  tags?: string;
  lavel?: string;
  team?: string;
  sort?: string;
  order?: string;
}

const defaultPlayersQuery = {
  limit: 100,
  skip: 0,
  sort: "points",
  order: "desc",
};

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_APP_BACKEND_ADMIN_API_ENDPOINT,
  prepareHeaders: async (headers) => {
    if (getToken) {
      const token = await getToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // Try to get a new token
    if (getToken) {
      await getToken();
      // Retry the initial query
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

interface DeleteParams {
  account: string;
  id: string;
}

interface DeletePlayerParams {
  account: string;
  player: string;
}

interface ImageUploadRequest {
  formData: FormData;
  account: string;
}

interface CopyAccountRequest {
  fromAccount: string;
  toAccount: string;
}

const API_URLS = {
  ACCOUNTS: "accounts",
  UPLOAD_IMAGE: "accounts/image",
  EVENTS: "events",
  MISSIONS: "missions",
  ACHIEVEMENTS: "achievements",
  LEVELS: "levels",
  LEADERBOARDS: "leaderboards",
  MYSTERYBOXES: "mysteryboxes",
  PLAYERS: "players",
  PRIZES: "prizes",
  RAFFLES: "raffles",
  QUIZZES: "quizzes",
  SURVEYS: "surveys",
  RULES: "rules",
  STREAK: "streaks",
  ANALYTICS: "analytics",
  TEAMS: "teams",
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Event",
    "Mission",
    "Account",
    "Analytics",
    "Achievement",
    "Level",
    "Leaderboard",
    "Mysterybox",
    "Player",
    "Prize",
    "Raffle",
    "Rule",
    "Quiz",
    "Survey",
    "Streak",
    "Analytic",
    "Team",
  ],
  // Account endpoints
  endpoints: (builder) => ({
    getAccounts: builder.query<Account[], void>({
      query: () => API_URLS.ACCOUNTS,
      providesTags: ["Account"],
    }),
    addAccount: builder.mutation<Account, Account>({
      query: (account) => ({
        url: API_URLS.ACCOUNTS,
        method: "POST",
        body: account,
      }),
      invalidatesTags: ["Account"],
    }),
    updateAccount: builder.mutation<Account, Account>({
      query: (account) => ({
        url: API_URLS.ACCOUNTS,
        method: "PUT",
        body: account,
      }),
      invalidatesTags: ["Account"],
    }),
    copyAccount: builder.mutation<void, CopyAccountRequest>({
      query: (body) => ({
        url: `${API_URLS.ACCOUNTS}/copy`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Account"],
    }),
    uploadImage: builder.mutation<{ path: string }, ImageUploadRequest>({
      query: ({ account, formData }) => ({
        url: `${API_URLS.UPLOAD_IMAGE}/${account}`,
        method: "POST",
        body: formData,
      }),
    }),
    // Event endpoints
    getEvents: builder.query<Event[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.EVENTS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Event"],
    }),
    addEvent: builder.mutation<Event, Event>({
      query: (event) => ({
        url: API_URLS.EVENTS,
        method: "POST",
        body: event,
      }),
      invalidatesTags: ["Event"],
    }),
    updateEvent: builder.mutation<Event, Event>({
      query: (event) => ({
        url: API_URLS.EVENTS,
        method: "PUT",
        body: event,
      }),
      invalidatesTags: ["Event"],
    }),
    deleteEvent: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.EVENTS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Event"],
    }),
    // Mission endpoints
    getMissions: builder.query<Mission[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.MISSIONS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Mission"],
    }),
    addMission: builder.mutation<Mission, Mission>({
      query: (mission) => ({
        url: API_URLS.MISSIONS,
        method: "POST",
        body: mission,
      }),
      invalidatesTags: ["Mission"],
    }),
    updateMission: builder.mutation<Mission, Mission>({
      query: (mission) => ({
        url: API_URLS.MISSIONS,
        method: "PUT",
        body: mission,
      }),
      invalidatesTags: ["Mission"],
    }),
    deleteMission: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.MISSIONS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Mission"],
    }),
    // Achievement endpoints
    getAchievements: builder.query<Achievement[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.ACHIEVEMENTS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Achievement"],
    }),
    addAchievement: builder.mutation<Achievement, Achievement>({
      query: (achievement) => ({
        url: API_URLS.ACHIEVEMENTS,
        method: "POST",
        body: achievement,
      }),
      invalidatesTags: ["Achievement"],
    }),
    updateAchievement: builder.mutation<Achievement, Achievement>({
      query: (achievement) => ({
        url: API_URLS.ACHIEVEMENTS,
        method: "PUT",
        body: achievement,
      }),
      invalidatesTags: ["Achievement"],
    }),
    deleteAchievement: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.ACHIEVEMENTS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Achievement"],
    }),
    // Level endpoints
    getLevels: builder.query<Level[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.LEVELS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Level"],
    }),
    addLevel: builder.mutation<Level, Level>({
      query: (level) => ({
        url: API_URLS.LEVELS,
        method: "POST",
        body: level,
      }),
      invalidatesTags: ["Level"],
    }),
    updateLevel: builder.mutation<Level, Level>({
      query: (level) => ({
        url: API_URLS.LEVELS,
        method: "PUT",
        body: level,
      }),
      invalidatesTags: ["Level"],
    }),
    deleteLevel: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.LEVELS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Level"],
    }),
    // Leaderboards
    getLeaderboards: builder.query<Leaderboard[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.LEADERBOARDS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Leaderboard"],
    }),
    addLeaderboard: builder.mutation<Leaderboard, Leaderboard>({
      query: (leaderboard) => ({
        url: API_URLS.LEADERBOARDS,
        method: "POST",
        body: leaderboard,
      }),
      invalidatesTags: ["Leaderboard"],
    }),
    updateLeaderboard: builder.mutation<Leaderboard, Leaderboard>({
      query: (leaderboard) => ({
        url: API_URLS.LEADERBOARDS,
        method: "PUT",
        body: leaderboard,
      }),
      invalidatesTags: ["Leaderboard"],
    }),
    deleteLeaderboard: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.LEADERBOARDS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Leaderboard"],
    }),
    // Mysteryboxes
    getMysteryboxes: builder.query<Mysterybox[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.MYSTERYBOXES,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Mysterybox"],
    }),
    addMysterybox: builder.mutation<Mysterybox, Mysterybox>({
      query: (mysterybox) => ({
        url: API_URLS.MYSTERYBOXES,
        method: "POST",
        body: mysterybox,
      }),
      invalidatesTags: ["Mysterybox"],
    }),
    updateMysterybox: builder.mutation<Mysterybox, Mysterybox>({
      query: (mysterybox) => ({
        url: API_URLS.MYSTERYBOXES,
        method: "PUT",
        body: mysterybox,
      }),
      invalidatesTags: ["Mysterybox"],
    }),
    deleteMysterybox: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.MYSTERYBOXES}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Mysterybox"],
    }),
    // Players
    getPlayers: builder.query<Player[], PlayersQueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.PLAYERS,
        params: { account, ...defaultPlayersQuery, ...params },
      }),
      providesTags: ["Player"],
    }),
    addPlayer: builder.mutation<Player, Player>({
      query: (player) => ({
        url: API_URLS.PLAYERS,
        method: "POST",
        body: player,
      }),
      invalidatesTags: ["Player"],
    }),
    updatePlayer: builder.mutation<Player, Player>({
      query: (player) => ({
        url: `${API_URLS.PLAYERS}/${player.player}`,
        method: "PATCH",
        body: player,
      }),
      invalidatesTags: ["Player"],
    }),
    deletePlayer: builder.mutation<void, DeletePlayerParams>({
      query: ({ account, player }) => ({
        url: `${API_URLS.PLAYERS}/${player}&account=${account}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Player"],
    }),
    // Prize
    getPrizes: builder.query<Prize[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.PRIZES,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Prize"],
    }),
    addPrize: builder.mutation<Prize, Prize>({
      query: (prize) => ({
        url: API_URLS.PRIZES,
        method: "POST",
        body: prize,
      }),
      invalidatesTags: ["Prize"],
    }),
    updatePrize: builder.mutation<Prize, Prize>({
      query: (prize) => ({
        url: API_URLS.PRIZES,
        method: "PUT",
        body: prize,
      }),
      invalidatesTags: ["Prize"],
    }),
    deletePrize: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.PRIZES}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Prize"],
    }),
    // Quizzes
    getQuizzes: builder.query<Quiz[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.QUIZZES,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Quiz"],
    }),
    addQuiz: builder.mutation<Quiz, Quiz>({
      query: (quiz) => ({
        url: API_URLS.QUIZZES,
        method: "POST",
        body: quiz,
      }),
      invalidatesTags: ["Quiz"],
    }),
    updateQuiz: builder.mutation<Quiz, Quiz>({
      query: (quiz) => ({
        url: API_URLS.QUIZZES,
        method: "PUT",
        body: quiz,
      }),
      invalidatesTags: ["Quiz"],
    }),
    deleteQuiz: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.QUIZZES}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Quiz"],
    }),
    // Raffle
    getRaffles: builder.query<Raffle[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.RAFFLES,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Raffle"],
    }),
    addRaffle: builder.mutation<Raffle, Raffle>({
      query: (raffle) => ({
        url: API_URLS.RAFFLES,
        method: "POST",
        body: raffle,
      }),
      invalidatesTags: ["Raffle"],
    }),
    updateRaffle: builder.mutation<Raffle, Raffle>({
      query: (raffle) => ({
        url: API_URLS.RAFFLES,
        method: "PUT",
        body: raffle,
      }),
      invalidatesTags: ["Raffle"],
    }),
    deleteRaffle: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.RAFFLES}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Raffle"],
    }),
    // Rule
    getRules: builder.query<Rule[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.RULES,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Rule"],
    }),
    addRule: builder.mutation<Rule, Rule>({
      query: (rule) => ({
        url: API_URLS.RULES,
        method: "POST",
        body: rule,
      }),
      invalidatesTags: ["Rule"],
    }),
    updateRule: builder.mutation<Rule, Rule>({
      query: (rule) => ({
        url: API_URLS.RULES,
        method: "PUT",
        body: rule,
      }),
      invalidatesTags: ["Rule"],
    }),
    deleteRule: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.RULES}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Rule"],
    }),
    // Streak
    getStreaks: builder.query<Streak[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.STREAK,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Streak"],
    }),
    addStreak: builder.mutation<Streak, Streak>({
      query: (streak) => ({
        url: API_URLS.STREAK,
        method: "POST",
        body: streak,
      }),
      invalidatesTags: ["Streak"],
    }),
    updateStreak: builder.mutation<Streak, Streak>({
      query: (streak) => ({
        url: API_URLS.STREAK,
        method: "PUT",
        body: streak,
      }),
      invalidatesTags: ["Streak"],
    }),
    deleteStreak: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.STREAK}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Streak"],
    }),
    // Survey
    getSurveys: builder.query<Survey[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.SURVEYS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Survey"],
    }),
    addSurvey: builder.mutation<Survey, Survey>({
      query: (survey) => ({
        url: API_URLS.SURVEYS,
        method: "POST",
        body: survey,
      }),
      invalidatesTags: ["Survey"],
    }),
    updateSurvey: builder.mutation<Survey, Survey>({
      query: (survey) => ({
        url: API_URLS.SURVEYS,
        method: "PUT",
        body: survey,
      }),
      invalidatesTags: ["Survey"],
    }),
    deleteSurvey: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.SURVEYS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Survey"],
    }),
    // Team
    getTeams: builder.query<Team[], QueryParams>({
      query: ({ account, ...params }) => ({
        url: API_URLS.TEAMS,
        params: { account, ...defaultQuery, ...params },
      }),
      providesTags: ["Team"],
    }),
    addTeam: builder.mutation<Team, Team>({
      query: (team) => ({
        url: API_URLS.TEAMS,
        method: "POST",
        body: team,
      }),
      invalidatesTags: ["Team"],
    }),
    updateTeam: builder.mutation<Team, Team>({
      query: (team) => ({
        url: API_URLS.TEAMS,
        method: "PUT",
        body: team,
      }),
      invalidatesTags: ["Team"],
    }),
    deleteTeam: builder.mutation<void, DeleteParams>({
      query: ({ account, id }) => ({
        url: `${API_URLS.TEAMS}/${account}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Team"],
    }),
    // Analytics
    getAnalyticsForAccount: builder.query<Analytics, string>({
      query: (account) => ({
        url: API_URLS.ANALYTICS,
        params: { account },
      }),
      providesTags: ["Analytics"],
    }),
    getAggregatedAnalytics: builder.query<Analytics, string>({
      query: () => ({
        url: API_URLS.ANALYTICS + "/aggregated",
      }),
      providesTags: ["Analytics"],
    }),
  }),
});

// Note to Robert: these are the methods you will need to import
// and use
// RTK Query's React integration will automatically generate React hooks for every endpoint we define!
export const {
  // Account admin API
  useGetAccountsQuery,
  useAddAccountMutation,
  useUpdateAccountMutation,
  useCopyAccountMutation,
  useUploadImageMutation,
  // Event admin APIs
  useGetEventsQuery,
  useAddEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  // Mission admin APIs
  useGetMissionsQuery,
  useAddMissionMutation,
  useUpdateMissionMutation,
  useDeleteMissionMutation,
  // Achievement admin APIs
  useGetAchievementsQuery,
  useAddAchievementMutation,
  useUpdateAchievementMutation,
  useDeleteAchievementMutation,
  // Level admin APIs
  useGetLevelsQuery,
  useAddLevelMutation,
  useUpdateLevelMutation,
  useDeleteLevelMutation,
  // Leaderboard admin APIs
  useGetLeaderboardsQuery,
  useAddLeaderboardMutation,
  useUpdateLeaderboardMutation,
  useDeleteLeaderboardMutation,
  // Mysterybox admin APIs
  useGetMysteryboxesQuery,
  useAddMysteryboxMutation,
  useUpdateMysteryboxMutation,
  useDeleteMysteryboxMutation,
  // Player admin APIs
  useGetPlayersQuery,
  useAddPlayerMutation,
  useUpdatePlayerMutation,
  useDeletePlayerMutation,
  // Prize admin APIs
  useGetPrizesQuery,
  useAddPrizeMutation,
  useUpdatePrizeMutation,
  useDeletePrizeMutation,
  // Quiz admin APIs
  useGetQuizzesQuery,
  useAddQuizMutation,
  useUpdateQuizMutation,
  useDeleteQuizMutation,
  // Raffle admin APIs
  useGetRafflesQuery,
  useAddRaffleMutation,
  useUpdateRaffleMutation,
  useDeleteRaffleMutation,
  // Rule API
  useGetRulesQuery,
  useAddRuleMutation,
  useUpdateRuleMutation,
  useDeleteRuleMutation,
  // Streak API
  useGetStreaksQuery,
  useAddStreakMutation,
  useUpdateStreakMutation,
  useDeleteStreakMutation,
  // Survey API
  useGetSurveysQuery,
  useAddSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  // Team API
  useGetTeamsQuery,
  useAddTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  // Analytics
  useGetAnalyticsForAccountQuery,
  useGetAggregatedAnalyticsQuery,
} = apiSlice;

export default apiSlice;
