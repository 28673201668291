// EventPage.tsx
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetTeamsQuery,
  useAddTeamMutation,
  useUpdateTeamMutation,
} from "@services/api/apiSlice";
import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "../../components/forms/BaseEntityForm";
import {
  CategoryInput,
  TagsInput,
  CreditsInput,
  PointsInput,
} from "@fields/Fields";

import { Team, defaultTeam } from "./Team";

const TeamPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: teams = [], isLoading } = useGetTeamsQuery({
    account: account?.account || "",
  });

  const [addNewTeam] = useAddTeamMutation();
  const [updateTeam] = useUpdateTeamMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const teamToEdit = teams.find((team) => team.id === id);
      if (!account || (teamToEdit && teamToEdit.account !== account.account)) {
        navigate("/teams");
      }
    }
  }, [teams, id, navigate, account, isNew]);

  const teamToEdit = teams.find((event) => event.id === id);
  const currentTeam = isNew
    ? { ...defaultTeam, account: account?.account || "" }
    : teamToEdit || defaultTeam;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Team>
      entityName="Teams"
      entityPath="team"
      entity={currentTeam}
      isNew={isNew}
      defaultEntity={defaultTeam}
      addEntity={(entity) => addNewTeam(entity).unwrap()}
      updateEntity={(entity) => updateTeam(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
            <PointsInput
              value={entity.points}
              onChange={(points) => updateField("points", points)}
              helpText="Points assigned to the team"
            />
            <CreditsInput
              value={entity.credits}
              onChange={(credits) => updateField("credits", credits)}
              helpText="Credits assigned to the team"
            />
          </div>
        </>
      )}
      additionalValidation={() => {}}
    />
  );
};

export default TeamPage;
