import {
  TimeRange,
  RefreshPeriod,
  defaultTimeRange,
  defaultElementBase,
  Requirements,
  defaultRequirements,
  Language,
  ElementBase,
  SubObjectives,
  Stock,
  defaultStock,
} from "@features/common/Common";

export interface Prize extends ElementBase {
  credits: number;
  active: TimeRange;
  period: RefreshPeriod;
  limitCount: boolean;
  countLimit: number;
  requirement: Requirements;
  languages: Language[];
  prizes: SubObjectives[];
  limitStock: boolean;
  stockCount: number;
  stock: Stock;
}

export const defaultPrize: Prize = {
  ...defaultElementBase,
  credits: 0,
  active: defaultTimeRange,
  period: "all-time",
  limitCount: true,
  countLimit: 1,
  requirement: defaultRequirements,
  languages: [],
  prizes: [],
  limitStock: true,
  stockCount: 1,
  stock: defaultStock,
};
