import React from 'react';
import Logo from "@elements/logo";
import AccountPicker from "@pickers/accountPicker";
import { Link } from "react-router-dom";
import Notifications from '@elements/notifications';
import {ActionButton} from '@components/buttons/actionButton';
import HeaderLogo from "@assets/header_logo.png";

export const Header: React.FC = () => {

  const handleSupportClick = () => {
    window.open('mailto:support@gamelayer.com', '_blank');
    const button = document.querySelector('.support-button');
    if (button) {
      const popup = document.createElement('div');
      popup.textContent = 'If your email app didn\'t open in a new tab, please contact support@gamelayer.com';
      popup.className = 'absolute bg-white border rounded-xl mt-2 border-black p-2 z-50';
      popup.style.top = `${button.getBoundingClientRect().bottom + window.scrollY}px`;
      popup.style.left = `${button.getBoundingClientRect().left + window.scrollX}px`;

      document.body.appendChild(popup);

      setTimeout(() => {
        document.body.removeChild(popup);
      }, 3000);
    }
  };

  return (
    <header className="w.full">
      <div className="flex flex-row p-4 w.full xl:max-w-[1200px] m-auto">
        <Link to={"/home"} className="flex flex-row gap-2 items-center flex-1">
          <Logo className="max-w-24 md:max-w-48" src={HeaderLogo} alt="GameLayer" />
        </Link>
        <div className='flex flex-1 flex-row gap-4 margin-auto'>
          <ActionButton >Data</ActionButton>
          <ActionButton className='support-button' onClick={() => handleSupportClick()}>Suport</ActionButton>
          <ActionButton variant="secondary" >Upgrade</ActionButton>
        </div>
        <div className='mr-6'>
          <Notifications />
        </div>
        <div>
          <AccountPicker  />
        </div>
      </div>
    </header>
  );
}