import React, { useState, useEffect, useCallback } from "react";
import { useGetMissionsQuery } from "@services/api/apiSlice";
import { ListPicker, ListItem } from "@pickers/listPicker";
import ItemPicker from "@pickers/itemPicker";
import { useAccount } from "@/features/accounts/useAccount";
import { Mission } from "@/features/missions/Mission";
import MissionPickerIcon from "@/assets/icons/MissionPicker.png";
import { LeaderboardDrivenBy } from "./Leaderboard";

interface MissionsPickerProps {
  drivenBy: LeaderboardDrivenBy;
  className?: string;
  onChange: (value: LeaderboardDrivenBy) => void;
}

const MissionsPicker: React.FC<MissionsPickerProps> = ({
  drivenBy,
  className,
  onChange,
}) => {
  const { account } = useAccount();
  const { data: missions = [], isLoading } = useGetMissionsQuery({
    account: account?.account || "",
  });

  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);

  // Initialize selected achievements from objectives
  useEffect(() => {
    if (!isLoading && missions.length > 0 && drivenBy.missions) {
      const selected = drivenBy.missions
        .filter((item) => missions.some((mission) => mission.id === item))
        .map((missionId) => {
          const mission = missions.find((mission) => mission.id === missionId)!;
          return { ...mission };
        });

      setSelectedMissions(selected);
    }
  }, [isLoading, missions, drivenBy.missions]);

  const handleSelect = useCallback(
    (item: ListItem) => {
      if (isLoading) return;

      const mission = missions.find((a) => a.id === item.id);
      if (!mission) return;

      const newSelection = selectedMissions.some((a) => a.id === item.id)
        ? selectedMissions.filter((a) => a.id !== item.id)
        : [...selectedMissions, mission];

      setSelectedMissions(newSelection);
      onChange({
        missions: newSelection.map((item) => item.id),
      });
    },
    [isLoading, missions, selectedMissions, onChange]
  );

  const handleDelete = useCallback(
    (id: string) => {
      const newSelection = selectedMissions.filter((item) => item.id !== id);
      setSelectedMissions(newSelection);
      onChange({
        missions: newSelection.map((item) => item.id),
      });
    },
    [selectedMissions, onChange]
  );

  const missionListItems = missions.map((item) => ({
    id: item.id,
    name: item.name,
    disabled: selectedMissions.some((selected) => selected.id === item.id),
  }));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-2">
          <ListPicker
            icon={MissionPickerIcon}
            items={missionListItems}
            currentValue={selectedMissions.at(-1)?.id}
            className={className}
            listType="Mission"
            onSelect={handleSelect}
            mode="select"
            placeholder="Mission Specific Leaderboard"
          />
        </div>
        <div className="col-start-2 row-start-2 space-y-4">
          {selectedMissions.map((item) => (
            <ItemPicker
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              title="Mission Name:"
              name={item.name}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MissionsPicker;
