import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import UserIcon from '@assets/icons/users.png';

const CurrentUser: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [currentUser, setCurrentUser] = useState<{ name?: string; picture?: string } | null>(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      setCurrentUser({
        name: user?.nickname || user?.name || 'John Doe',
        picture: user.picture || UserIcon,
      });
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to see user information.</div>;
  }

  return (
    <div>
      {currentUser && (
        <div className='flex p-2 items-center w-full my-2'>
          <img className='rounded-full w-8 h-8' src={currentUser.picture} alt="User Avatar" />
            <p className='ml-2 text-sm overflow-hidden break-words'>{currentUser.name}</p>
        </div>
      )}
    </div>
  );
};

export default CurrentUser;