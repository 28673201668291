import { defaultElementBase, ElementBase } from "@features/common/Common";

// For player, we don't use 'id' property
export interface Player extends Omit<ElementBase, "id"> {
  player: string;
  points: number;
  credits: number;
  team: string;
  badges: string[];
}

export const defaultPlayer: Player = {
  ...defaultElementBase,
  player: "",
  team: "",
  credits: 0,
  points: 0,
  badges: [],
};
