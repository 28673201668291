import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link } from "react-router-dom";
import { LogoutButton } from "@buttons/logoutButton";

import homeIcon from '@assets/icons_bw/home.png';
import accountsIcon from '@assets/icons_bw/player.png';
import missionsIcon from '@assets/icons_bw/mission.png';
import streaksIcon from '@assets/icons_bw/event.png';
import eventsIcon from '@assets/icons_bw/event.png';
import achievementsIcon from '@assets/icons_bw/achievement.png';
import leaderboardsIcon from '@assets/icons_bw/leaderboard.png';
import levelsIcon from '@assets/icons_bw/level.png';
import prizesIcon from '@assets/icons_bw/prize.png';
import rafflesIcon from '@assets/icons_bw/raffle.png';
import mysteryboxesIcon from '@assets/icons_bw/mysterybox.png';
import surveysIcon from '@assets/icons_bw/survey.png';
import playersIcon from '@assets/icons_bw/player.png';
import teamsIcon from '@assets/icons_bw/team.png';

const icons: { [key: string]: { icon: string, path: string } } = {
  home: {
    icon: homeIcon,
    path: '/home',
  },
  accounts: {
    icon: accountsIcon,
    path: '/accounts',
  },
  missions: {
    icon: missionsIcon,
    path: '/missions/list',
  },
  streaks: {
    icon: streaksIcon,
    path: '/streaks/list',
  },
  events: {
    icon: eventsIcon,
    path: '/events/list',
  },
  achievements: {
    icon: achievementsIcon,
    path: '/achievements/list',
  },
  leaderboards: {
    icon: leaderboardsIcon,
    path: '/leaderboards/list',
  },
  levels: {
    icon: levelsIcon,
    path: '/levels/list',
  },
  prizes: {
    icon: prizesIcon,
    path: '/prizes/list',
  },
  raffles: {
    icon: rafflesIcon,
    path: '/raffles/list',
  },
  mysteryboxes: {
    icon: mysteryboxesIcon,
    path: '/mysteryboxes/list',
  },
  surveys: {
    icon: surveysIcon,
    path: '/surveys/list',
  },
  players: {
    icon: playersIcon,
    path: '/players/list',
  },
  teams: {
    icon: teamsIcon,
    path: '/teams/list',
  },
};

const NavbarButtons: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <nav>
      <ul className="nav flex flex-col gap-2 m-2">
        {Object.keys(icons).map((key) => (
          <li key={key}>
            <Link className="flex gap-2 m-2 hover:underline" to={icons[key].path}>
              <img className="w-6 h-6" src={icons[key].icon} alt={`${key} icon`} />
              <span className="capitalize">{key}</span>
            </Link>
          </li>
        ))}
        <hr />
        {isAuthenticated && <LogoutButton />}
      </ul>
    </nav>
  );
};

export default NavbarButtons;
