import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "@/features/accounts/useAccount";
import { usePermissions } from "@/features/permissions/usePermissions";

import { ActionButton } from "@buttons/actionButton";
import { PageLayout } from "@layout/page-layout";
import Loader from "@elements/loader";
import EntityList from "@/components/lists/entity-list";

import { Entity } from "@/features/common/Common";
import { Player } from "@/features/players/Player";

import { 
  useGetAchievementsQuery,
  useGetEventsQuery,
  useGetLeaderboardsQuery,
  useGetLevelsQuery,
  useGetMissionsQuery,
  useGetMysteryboxesQuery,
  useGetPlayersQuery,
  useGetPrizesQuery,
  useGetRafflesQuery,
  useGetStreaksQuery,
  useGetSurveysQuery,
  useGetTeamsQuery,
} from "@services/api/apiSlice";

/**
 * Represents the Events Page component.
 * This component displays a list of events and allows users to create, edit, delete, and duplicate events.
 */
const EntityListPage: React.FC = () => {
  const { entityType } = useParams<{ entityType: string }>();
  const { account } = useAccount();
  const [entities, setEntities] = useState<Entity[] | Player[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { canWrite } = usePermissions();

  const {
    data: events = [],
    error: eventsError,
    isLoading: eventsLoading,
  } = useGetEventsQuery({ account: account?.account || "" });

  const {
    data: achievements = [],
    error: achievementsError,
    isLoading: achievementsLoading,
  } = useGetAchievementsQuery({ account: account?.account || "" });

  const {
    data: leaderboards = [],
    error: leaderboardsError,
    isLoading: leaderboardsLoading,
  } = useGetLeaderboardsQuery({ account: account?.account || "" });

  const {
    data: levels = [],
    error: levelsError,
    isLoading: levelsLoading,
  } = useGetLevelsQuery({ account: account?.account || "" });

  const {
    data: missions = [],
    error: missionsError,
    isLoading: missionsLoading,
  } = useGetMissionsQuery({ account: account?.account || "" });

  const {
    data: prizes = [],
    error: prizesError,
    isLoading: prizesLoading,
  } = useGetPrizesQuery({ account: account?.account || "" });

  const {
    data: raffles = [],
    error: rafflesError,
    isLoading: rafflesLoading,
  } = useGetRafflesQuery({ account: account?.account || "" });

  const {
    data: surveys = [],
    error: surveysError,
    isLoading: surveysLoading,
  } = useGetSurveysQuery({ account: account?.account || "" });

  const {
    data: teams = [],
    error: teamsError,
    isLoading: teamsLoading,
  } = useGetTeamsQuery({ account: account?.account || "" });

  const {
    data: players = [],
    error: playersError,
    isLoading: playersLoading,
  } = useGetPlayersQuery({ account: account?.account || "" });

  const {
    data: mysteryBoxes = [],
    error: mysteryBoxesError,
    isLoading: mysteryBoxesLoading,
  } = useGetMysteryboxesQuery({ account: account?.account || "" });

  const {
    data: streaks = [],
    error: streaksError,
    isLoading: streaksLoading,
  } = useGetStreaksQuery({ account: account?.account || "" });

  React.useEffect(() => {
    switch (entityType) {
      case "achievements":
      setEntities(achievements);
      setError(achievementsError ? achievementsError.toString() : "");
      setIsLoading(achievementsLoading);
      break;
    case "events":
      setEntities(events);
      setError(eventsError ? eventsError.toString() : "");
      setIsLoading(eventsLoading);
      break;
    case "leaderboards":
      setEntities(leaderboards);
      setError(leaderboardsError ? leaderboardsError.toString() : "");
      setIsLoading(leaderboardsLoading);
      break;
    case "levels":
      setEntities(levels);
      setError(levelsError ? levelsError.toString() : "");
      setIsLoading(levelsLoading);
      break;
    case "missions":
      setEntities(missions);
      setError(missionsError ? missionsError.toString() : "");
      setIsLoading(missionsLoading);
      break;
    case "mysteryboxes":
      setEntities(mysteryBoxes);
      setError(mysteryBoxesError ? mysteryBoxesError.toString() : "");
      setIsLoading(mysteryBoxesLoading);
      break;
    case "players":
      setEntities(players);
      setError(playersError ? playersError.toString() : "");
      setIsLoading(playersLoading);
      break;
    case "prizes":
      setEntities(prizes);
      setError(prizesError ? prizesError.toString() : "");
      setIsLoading(prizesLoading);
      break;
    case "raffles":
      setEntities(raffles);
      setError(rafflesError ? rafflesError.toString() : "");
      setIsLoading(rafflesLoading);
      break;
    case "streaks":
      setEntities(streaks);
      setError(streaksError ? streaksError.toString() : "");
      setIsLoading(streaksLoading);
      break;
    case "surveys":
      setEntities(surveys);
      setError(surveysError ? surveysError.toString() : "");
      setIsLoading(surveysLoading);
      break;
    case "teams":
      setEntities(teams);
      setError(teamsError ? teamsError.toString() : "");
      setIsLoading(teamsLoading);
      break;
    default:
      setEntities([]);
      setError("Invalid entity type");
      setIsLoading(false);
      break;
    }
  }, [entityType, events, eventsError, eventsLoading, achievements, achievementsError, achievementsLoading, leaderboards, leaderboardsError, leaderboardsLoading, levels, levelsError, levelsLoading, missions, missionsError, missionsLoading]);

  return (
    <PageLayout>
      <div className="flex  max-w-full p-6 justify-items items-center">
        <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900 capitalize">
          {entityType}
        </h1>
        {canWrite() && (
          <div className="flex flex-row gap-4">
            <ActionButton to={`/${entityType}/new`}>Create {entityType}</ActionButton>
          </div>
        )}
      </div>
      <section className="events-list">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <div>Error displaying entities.</div>
        ) : (
          <EntityList entities={entities} entityType={entityType || ''} />
        )}
      </section>
    </PageLayout>
  );
};

export default EntityListPage;
