import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Toaster } from "@/components/ui/toaster";

import { useUser } from "@features/user/useUser";

import { PageLoader } from "@layout/page-loader";
import { AuthenticationGuard } from "@layout/authentication-guard";
import { CallbackPage } from "@pages/callback-page";
import { HomePage } from "@pages/home-page";
import { NotFoundPage } from "@pages/not-found-page";
import { ProfilePage } from "@pages/profile-page";
import { ProtectedPage } from "@pages/protected-page";
import { PublicPage } from "@pages/public-page";
import AccountsPage from "@features/accounts/accountsPage";
import AccountPage from "@features/accounts/accountPage";
import AchievementPage from "@/features/achievements/achievementPage";
import AnalyticsPage from "@/pages/analytics-page";
import EntityListPage from "@/pages/entity-listing-page";
import EventPage from "@features/events/eventPage";
import LeaderboardPage from "@features/leaderboards/leaderboardPage";
import LevelPage from "@/features/levels/levelPage";
import MissionPage from "@features/missions/missionPage";
import MysteryboxPage from "@features/mysteryboxes/mysteryboxPage";
import PlayerPage from "@features/players/playerPage";
import PrizePage from "@features/prizes/prizePage";
import RafflePage from "@features/raffles/rafflePage";
import StreakPage from "@features/streaks/streakPage";
import SurveyPage from "@/features/surveys/surveyPage";
import TeamPage from "@/features/teams/teamPage";
import { setAuthToken } from "@services/api/apiSlice";

import "./App.css";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const { setUserFromToken } = useUser();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Get the token
        const token = await getAccessTokenSilently();

        // Set the token for API calls
        setAuthToken(() => getAccessTokenSilently());

        // Set user context from token
        setUserFromToken(token);
      } catch (error) {
        console.error("Error initializing auth:", error);
      }
    };

    if (!isLoading) {
      initializeAuth();
    }
  }, [getAccessTokenSilently, isLoading, setUserFromToken]);

  /*
  useEffect(() => {
    setAuthToken(() => getAccessTokenSilently());
  }, [getAccessTokenSilently]);
  */

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoading) {
        setShowLoader(false);
      }
    }, 1500); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isLoading || showLoader) {
    return <PageLoader />;
  }

  return (
    <div>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/home"
            element={<AuthenticationGuard component={AnalyticsPage} />}
          />
          <Route
            path="/profile"
            element={<AuthenticationGuard component={ProfilePage} />}
          />
          <Route
            path="/accounts"
            element={<AuthenticationGuard component={AccountsPage} />}
          />
          <Route
            path="/:entityType/list"
            element={<AuthenticationGuard component={EntityListPage} />}
          />
          <Route
            path="/accounts/:account"
            element={<AuthenticationGuard component={AccountPage} />}
          />
          <Route
            path="/levels/:id"
            element={<AuthenticationGuard component={LevelPage} />}
          />
          <Route
            path="/events/:id"
            element={<AuthenticationGuard component={EventPage} />}
          />
          <Route
            path="/missions/:id"
            element={<AuthenticationGuard component={MissionPage} />}
          />
          <Route
            path="/streaks/:id"
            element={<AuthenticationGuard component={StreakPage} />}
          />
          <Route
            path="/achievements/:id"
            element={<AuthenticationGuard component={AchievementPage} />}
          />
          <Route
            path="/leaderboards/:id"
            element={<AuthenticationGuard component={LeaderboardPage} />}
          />
          <Route
            path="/prizes/:id"
            element={<AuthenticationGuard component={PrizePage} />}
          />
          <Route
            path="/raffles/:id"
            element={<AuthenticationGuard component={RafflePage} />}
          />
          <Route
            path="/mysteryboxes/:id"
            element={<AuthenticationGuard component={MysteryboxPage} />}
          />
          <Route
            path="/players/:player"
            element={<AuthenticationGuard component={PlayerPage} />}
          />
          <Route
            path="/teams/:id"
            element={<AuthenticationGuard component={TeamPage} />}
          />
          <Route
            path="/surveys/:id"
            element={<AuthenticationGuard component={SurveyPage} />}
          />
          <Route path="/public" element={<PublicPage />} />
          <Route
            path="/protected"
            element={<AuthenticationGuard component={ProtectedPage} />}
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Toaster />
    </div>
  );
};

export default App;
